import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {AppSharedModule} from '../shared/shared.module';
import {HomeComponent} from './home.component';
import {HOME_ROUTES} from './home.route';

@NgModule({
  imports: [AppSharedModule, RouterModule.forChild([...HOME_ROUTES])],
  declarations: [HomeComponent],
})
export class AppHomeModule {
}
