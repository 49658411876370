export default {
    'app.title': 'Kõlvikute kaardirakendus',
    'kaart.pohi_vr2': 'Põhikaart',
    'kaart.pohi_vv': 'Värviline põhikaart (reljeefvarjutusega)',
    'kaart.pohi_mvr2': 'Mustvalge põhikaart',
    'kaart.hybrid': 'Hübriidkaart',
    'kaart.kaart_ht': 'Hallkaart',
    'kaart.kaart24l': 'Kaart (valgete teedega)',
    'kaart.eestifoto': 'Ortofoto',
    cadasters: 'Katastripiirid',
    land_parcels_current: 'Kõlvikud',
    land_parcels_historical_1: 'Kõlvikute ajalugu (vasak)',
    land_parcels_historical_2: 'Kõlvikute ajalugu (parem)',
    cadastre_unit_current: 'Katastripiirid',
    cadastre_unit_historical_1: 'Katastripiirid ajalugu (vasak)',
    cadastre_unit_historical_2: 'Katastripiirid ajalugu (parem)',
    'map.layers': 'Kihid',
    'map.changeOpacity': 'Muuda läbipaistvust',
    'map.attribution-prefix': 'Kaardipilt: Maa-Amet ',
    'map.baseLayers': 'Aluskaardid',
    'map.featureLayers': 'Kaardikihid',
    'map.history': 'Andmete ajalugu',
    'map.historical.dpLeft': 'Vasak',
    'map.historical.dpRight': 'Parem',
    'map.location': 'Positsioneerimine',
    'map.marker': 'Kaardi nihutamine',
    'map.ruler': 'Mõõtmine',
    'map.print': 'Printimine',
    'map.markerForm.title': 'Kaardi nihutamine',
    'map.markerForm.location': 'Aaadress / Katastriüksuse tunnus',
    'map.markerForm.x': 'X-koordinaat',
    'map.markerForm.y': 'Y-koordinaat',
    'map.markerForm.button': 'Nihuta kaarti',
    'map.markerForm.tab.address': 'Aadress',
    'map.markerForm.tab.xy': 'XY koordinaadid',
    'map.historyForm.cancel': 'Loobu',
    'map.historyForm.apply': 'Rakenda',
    'map.historyForm.title': 'Vali, millise kuupäeva seisuga soovid andmeid võrrelda',
    'map.legend.title':'Legend',
    'loader.loading': 'Andmeid laetakse',
    'inads.search.placeholder': 'Sisesta aadress või tunnus',
    'inads.search.error': 'Viga, midagi läks valesti!',
    'main.card.search': 'Otsing',
    'main.card.history': 'Ajalugu',
    'main.card.history.label': 'Vali, millise kuupäeva seisuga soovid andmed vaadelda:',
    'land_parcel.details.title': 'Kõlvik',
    'land_parcel.details.summary_title': 'Kõlvikute kokkuvõte',
    'land_parcel.details.type': 'Tüüp',
    'land_parcel.details.area': 'Pindala',
    'land_parcel.details.version_date': 'Versiooni kpv',
    'land_parcel.details.etak.title': 'ETAK objektid',
    'land_parcel.history': 'Andmete ajalugu',
    'land_parcel.show_in_ha': 'Kuva andmeid hektarites',
    'land_parcel.show_in_m2': 'Kuva andmeid ruutmeetrites',
    'land_parcel.download': 'Lae alla',
    'sidebar.close': 'Kitsenda menüü',
    'sidebar.history': 'Andmete ajalugu',
    'sidebar.map': 'Kaart',
    'sidebar.api': 'API',
    'sidebar.landParcelsManagement': 'Kõlvikute reeglid',
    'sidebar.buffersManagement': 'Puhvrite haldus',
    'sidebar.admin': 'Administreerimine',
    'sidebar.login': 'Logi sisse',
    'sidebar.logout': 'Logi välja',
    'sidebar.open_data': 'Avaandmed',
    'measuringTooltip.title': 'Mõõtmine',
    'measuringTooltip.length': 'Mõõda joone pikkust',
    'measuringTooltip.area': 'Mõõda pindala',
    'measuringTooltip.clear': 'Puhasta',
    'measuringTooltip.continuePolygonMsg': 'Pindala mõõtmine',
    'measuringTooltip.continueLineMsg': 'Joone pikkuse mõõtmine',
    'measuringTooltip.startMsg': 'Mõõtmiseks vali kaardil punkt',
    'cadastre_unit.details.title': 'Maatükk',
    'cadastre_unit.details.area': 'Pindala',
    'cadastre_unit.details.version_date': 'Versiooni kpv',
    'cadastre_unit.details.land_parcels.title': 'Kõlvikud',
    'cadastre_unit.details.land_parcels.summary_title': 'Kõlvikute kokkuvõte',
    'cadastre_unit.details.land_parcels.summary_info': 'Andmed kuvatakse kooskõlas Katastriüksuse moodustamise korrale. Kõlvikute täpsema paiknemise, sh kõlvikute, mille pindala on väiksem kui 25 m2 ja mis ei moodusta 5 % katastriüksuse pindalast vaatamiseks ',
    'cadastre_unit.details.land_parcels.details_info': 'Katastriüksuse kõlvikulise koosseisu vaatamiseks ',
    'button.cancel': 'Katkesta',
    'button.download': 'Lae alla',
    'button.click': 'vajuta siia',
    'button.print': 'Prindi',
    'download.title': 'Lae alla',
    'button.save': 'Salvesta',
    'button.wait': 'Palun oodake',
    'title.landParcelsManagement': 'Kõlvikute reeglid',
    'landParcelDefinition.code': 'Kõlviku kood',
    'landParcelDefinition.name': 'Kõlviku nimi',
    'landParcelDefinition.hide': 'Peidetud',
    'landParcelDefinition.include': 'Kõlviku objektid',
    'landParcelDefinition.include.code': 'Objekti kood',
    'landParcelDefinition.include.type': 'Objekti tüüp',
    'landParcelDefinition.exclude': 'Kõlviku lõikajad',
    'landParcelDefinition.exclude.code': 'Objekti kood',
    'landParcelDefinition.exclude.type': 'Objekti tüüp',
    'landParcelDefinition.rule': 'Reeglid',
    'landParcelDefinition.rule.code': 'Reegli kood',
    'message.saved': 'Andmed salvestatud',
    'error.saveFailed': 'Andmete salvestamine ebaõnnestus',
    'title.topologyObjectBufferManagement': 'ETAK objektide buffrid',
    'topologyObjectBufferDefinition.code': 'ETAK objekti kood',
    'topologyObjectBufferDefinition.type': 'ETAK objekti tüüp',
    'topologyObjectBufferDefinition.defaultBuffer': 'Buffri väikeväärtus',
    'topologyObjectBufferDefinition.buffers': 'Buffri suurused',
    'topologyObjectBufferDefinition.buffer.width': 'Laius',
    'topologyObjectBufferDefinition.buffer.value': 'Suurus',
    'title.landParcelGeneration': 'Kõlvikute genereerimine',
    'title.landParcelGeneration.overview': 'Kõlvikute genereerimise ülevaade',
    'title.landParcelGeneration.info': 'Kõlvikute genereerimise olek',
    'title.landParcelGeneration.history': 'Kõlvikute genereerimise ajalugu',
    'sidebar.landParcelGeneration': 'Kõlvikute genereerimine',
    'title.cadastreUnitLandParcelGeneration': 'Katastri kõlvikute genereerimine',
    'title.cadastreUnitLandParcelGeneration.overview': 'Katastri kõlvikute genereerimise ülevaade',
    'title.cadastreUnitLandParcelGeneration.info': 'Katastri kõlvikute genereerimise olek',
    'title.cadastreUnitLandParcelGeneration.history': 'Katastri kõlvikute genereerimise ajalugu',
    'sidebar.cadastreUnitLandParcelGeneration': 'Katastri kõlvikute genereerimine',
    'generationJob.key': 'Tüüp',
    'generationJob.generationDate': 'Kuupäev',
    'generationJob.currentStep': 'Olek',
    'generationJob.status': 'Staatus',
    'generationJob.createdDate': 'Loodud',
    'generationInfo.queue.name': 'Nimi',
    'generationInfo.queue.messageCount': 'Sõnumite arv',
    'generationInfo.queue.consumerCount': 'Tarbijaid',
    'landParcelGeneration.generate': 'Genereeri kõlvikud',
    'landParcelGeneration.generate.full': 'Genereeri kõik',
    'landParcelGeneration.generate.changes': 'Genereeri muudatused',
    'landParcelGeneration.generate.purgeAndGenerate': 'Puhasta ja genereeri kõik',
    'landParcelGeneration.generate.clearExisting': 'Puhasta eelnev töö',
    'job.generateLandParcels': 'Kõlvikute genereerimine',
    'generationInfo.lastDate': 'Viimase genereerimise kuupäev',
    'generationInfo.dates': 'Genereeritud kuupäevad',
    'message.landParcelGeneration.generate.started': 'Kõlvikute genereerimine käivitatud',
    'error.landParcelGeneration.generate.failed': 'Kõlvikute genereerimise käivitamine ebaõnnestus',
    'job.generateCadastreUnitLandParcels': 'Katastri kõlvikute genereerimine',
    'cadastreUnitLandParcelGeneration.generate': 'Genereeri kõlvikud',
    'cadastreUnitLandParcelGeneration.generate.full': 'Genereeri kõik',
    'cadastreUnitLandParcelGeneration.generate.changes': 'Genereeri muudatused',
    'cadastreUnitLandParcelGeneration.generate.purgeAndGenerate': 'Puhasta ja genereeri kõik',
    'cadastreUnitLandParcelGeneration.generate.clearExisting': 'Puhasta eelnev töö',
    'message.cadastreUnitLandParcelGeneration.generate.started': 'Kõlvikute genereerimine käivitatud',
    'error.cadastreUnitLandParcelGeneration.generate.failed': 'Kõlvikute genereerimise käivitamine ebaõnnestus',
    'title.jobs': 'Taustatööd',
    'sidebar.jobs': 'Taustatööd',
    'job.jobName': 'Nimetus',
    'job.jobStatus': 'Olek',
    'job.lastExecutedStatus': 'Viimane tulemus',
    'job.lastDate': 'Andmete seis',
    'job.lastError': 'Viimane viga',
    'job.lastExecutedTime': 'Viimase käivituse aeg',
    'job.nextFireTime': 'Järgmise käivituse aeg',
    'job.cronExpression': 'CRON',
    'job.action': '...',
    'job.pause': 'Peata',
    'job.resume': 'Taaskäivita',
    'job.run': 'Käivita',
    'message.job.started': 'Käivitatud',
    'message.job.paused': 'Peatatud',
    'message.job.resumed': 'Taaskäivitatud',
    'error.failedToLoadJobs': 'Taustatööde laadimine ebaõnnestus',
    'error.job.failed': 'Tekkis viga',
    'cadastre_unit_print.title': 'KÜ kõlvikute kokkuvõtte printimine',
    'cadastre_unit_print.page_size': 'Paberi formaat',
    'cadastre_unit_print.orientation': 'Paberi orientatsioon',
    'cadastre_unit_print.orientation.portrait': 'portrait',
    'cadastre_unit_print.orientation.landscape': 'landscape',
    'cadastre_unit_print.scale': 'Mõõtkava',
    'cadastre_unit_print.sign': 'Allkirjasta digitaalselt',
    'cadastre_unit_print.safari': '<strong>Tähelepanu!</strong> Safari lehitsejaga võib väljatrükkidel esineda probleeme. Nende tekkimisel palun kasutage alternatiivseid lehitsejaid, nagu näiteks Chrome, Edge või Firefox',
};
