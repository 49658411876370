import {Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MapComponent} from '../map/map.component';
import {MapService} from '../map/map.service';
import {GlobalStateService} from "../service/global-state.service";
import {Subscription} from "rxjs";


@Component({
  selector: 'map-wrapper',
  templateUrl: './map-wrapper.component.html',
  styleUrls: ['./map-wrapper.component.scss']
})
export class MapWrapperComponent implements OnInit, OnDestroy {

  @ViewChild('map', {static: true}) map!: MapComponent;
  @ViewChild('top', {static: true}) top!: ElementRef;
  @ViewChild('right', {static: true}) right!: ElementRef;
  @ViewChild('left', {static: true}) left!: ElementRef;
  @ViewChild('bottom', {static: true}) bottom!: ElementRef;
  @ViewChild('preview', {static: true}) preview!: ElementRef;

  pageWidth = 0;
  pageHeight = 0;

  showPreview: Subscription;
  showPreviewDim: Subscription;
  backdrop: Subscription;
  previewVisible = false;

  marginTop = 55;

  showBackdrop = false;


  constructor(private mapService: MapService, protected globalStateService: GlobalStateService) {
    this.registerShowPreviewModifications();
    this.registerShowPreviewDimModifications();
  }

  ngOnInit(): void {
    this.mapService.initMap(this.map);
    this.onResize(null);
    this.backdrop = this.globalStateService.getBackdrop().subscribe(value => this.showBackdrop = value)
  }

  registerShowPreviewModifications() {
    this.showPreview = this.globalStateService.getPrintPreview().subscribe(value => {
      this.previewVisible = value;
      this.calculatePagePreview(this.pageWidth, this.pageHeight, window.innerWidth, window.innerHeight, this.previewVisible);
    })
  }

  registerShowPreviewDimModifications() {
    this.showPreviewDim = this.globalStateService.getPrintPreviewDim().subscribe(value => {
      if (value.length === 2) {
        this.pageWidth = value[0];
        this.pageHeight = value[1];
        this.calculatePagePreview(this.pageWidth, this.pageHeight, window.innerWidth, window.innerHeight, this.previewVisible);
      }
    })
  }

  @HostListener('window:resize', ['$event'])
  onResize($event): void {
    const target = $event?.target;
    if (target) {
      this.calculatePagePreview(this.pageWidth, this.pageHeight, target.innerWidth, target.innerHeight, this.previewVisible);
    }
  }

  calculatePagePreview(pageWidth: number, pageHeight: number, clientWidth: number, clientHeight: number, visible: boolean) {
    const vertical = Math.round((clientHeight - pageHeight) / 2);
    const horizontal = Math.round((clientWidth - pageWidth) / 2);

    this.resizeElementRef(this.top, visible && (vertical > 1 + this.marginTop), pageWidth > clientWidth ? clientWidth : pageWidth, vertical, 1 + this.marginTop, horizontal);
    this.resizeElementRef(this.bottom, visible && (pageHeight < clientHeight), pageWidth, vertical - this.marginTop, null, horizontal, 1);
    this.resizeElementRef(this.left, visible, horizontal, pageHeight > clientHeight ? clientHeight - this.marginTop : pageHeight, vertical + this.marginTop, null, null, 1);
    this.resizeElementRef(this.right, visible, horizontal, pageHeight > clientHeight ? clientHeight - this.marginTop : pageHeight, vertical + this.marginTop, 1);
  }

  resizeElementRef(element: ElementRef, visible: boolean, width: number, height: number, top?: number, right?: number, bottom?: number, left?: number): void {

    if (element) {
      element.nativeElement.style.width = this.toPixels(width);
      element.nativeElement.style.height = this.toPixels(height);
      element.nativeElement.style.display = visible ? 'block' : 'none'

      if (top) {
        element.nativeElement.style.top = this.toPixels(top);
      }

      if (right) {
        element.nativeElement.style.right = this.toPixels(right);
      }

      if (bottom) {
        element.nativeElement.style.bottom = this.toPixels(bottom);
      }

      if (left) {
        element.nativeElement.style.left = this.toPixels(left);
      }

    }
  }

  toPixels(number: number): string {
    return number > 0 ? number + 'px' : '0';
  }

  ngOnDestroy(): void {
    if (this.showPreview) {
      this.showPreview.unsubscribe();
    }

    if (this.showPreviewDim) {
      this.showPreviewDim.unsubscribe();
    }

    if (this.backdrop) {
      this.backdrop.unsubscribe();
    }
  }


}
