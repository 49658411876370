<div class="d-flex modal-header justify-content-between">
  <h5 class="d-flex">{{'download.title'| translate}}</h5>
  <a href="#" (click)="activeModal.dismiss()">
    <fa-icon [icon]="['fas', 'times']" class="icon mr-2"></fa-icon>
  </a>
</div>
<div class="modal-body">
  <div class="row my-2 py-2">
    <div class="col-12">
      <div class="form-check" *ngFor="let format of formats">
        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" [value]="format" [(ngModel)]="selectedFormat">
        <label class="form-check-label" for="flexRadioDefault1">
          {{format}}
        </label>
      </div>
    </div>
  </div>

</div>
<div class="modal-footer">
  <div class="d-flex flex-column flex-md-row w-100">
    <div class="d-flex w-100">
      <button type="button" class="btn btn-secondary-outlined w-100 mx-2" (click)="activeModal.dismiss()">
        <span translate="button.cancel"></span>
      </button>
    </div>
    <div class="d-flex w-100">
      <button type="button" class="btn btn-primary-blue w-100 mx-2"
              (click)="download()" [disabled]="!selectedFormat"><span
        translate="button.download"></span>
      </button>
    </div>
  </div>
</div>
