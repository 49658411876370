<div class="sidebar">
  <ul>
    <li class="d-flex flex-row" (click)="close()">
      <fa-icon class="d-flex icon align-self-center" [icon]="['fas', 'chevron-left']"></fa-icon>
    </li>
    <li class="d-flex flex-row">
      <fa-icon class="d-flex icon align-self-center" [icon]="['fas', 'history']"></fa-icon>
      <span class="align-self-center" (click)="showHistory()">{{'sidebar.history' | translate}}</span>
    </li>
    <li class="d-flex flex-row">
      <fa-icon class="d-flex icon align-self-center" [icon]="['fas', 'folder-open']"></fa-icon>
      <a [href]="getOpenDataURl()" target="_blank" class="align-self-center">{{'sidebar.open_data' | translate}}</a>
    </li>
    <li class="d-flex flex-row" *hasAnyAuthority="'ROLE_ADMIN'">
      <fa-icon class="d-flex icon align-self-center" [icon]="['far', 'map']"></fa-icon>
      <a routerLink="/" class="align-self-center">{{'sidebar.map' | translate}}</a>
    </li>
    <li class="d-flex flex-row" *hasAnyAuthority="'ROLE_ADMIN'">
      <fa-icon class="d-flex icon align-self-center" [icon]="['fas', 'code']"></fa-icon>
      <a (click)="openDocs()" class="align-self-center">{{'sidebar.api' | translate}}</a>
    </li>
    <li class="d-flex flex-row" *hasAnyAuthority="'ROLE_ADMIN'">
      <fa-icon class="d-flex icon align-self-center" [icon]="['fas', 'shapes']"></fa-icon>
      <a (click)="openLandParcelsManagement()" class="align-self-center">{{'sidebar.landParcelsManagement' | translate}}</a>
    </li>
    <li class="d-flex flex-row" *hasAnyAuthority="'ROLE_ADMIN'">
      <fa-icon class="d-flex icon align-self-center" [icon]="['fas', 'shapes']"></fa-icon>
      <a (click)="openBufferDefinitionsManagement()" class="align-self-center">{{'sidebar.buffersManagement' | translate}}</a>
    </li>
    <li class="d-flex flex-row" *hasAnyAuthority="'ROLE_ADMIN'">
      <fa-icon class="d-flex icon align-self-center" [icon]="['fas', 'shapes']"></fa-icon>
      <a (click)="openLandParcelGeneration()" class="align-self-center">{{'sidebar.landParcelGeneration' | translate}}</a>
    </li>
      <li class="d-flex flex-row" *hasAnyAuthority="'ROLE_ADMIN'">
          <fa-icon class="d-flex icon align-self-center" [icon]="['fas', 'shapes']"></fa-icon>
          <a (click)="openCadastreUnitLandParcelGeneration()" class="align-self-center">{{'sidebar.cadastreUnitLandParcelGeneration' | translate}}</a>
      </li>
    <li class="d-flex flex-row" *hasAnyAuthority="'ROLE_ADMIN'">
      <fa-icon class="d-flex icon align-self-center" [icon]="['fas', 'shapes']"></fa-icon>
      <a (click)="openJobs()" class="align-self-center">{{'sidebar.jobs' | translate}}</a>
    </li>
    <li class="d-flex flex-row" (click)="login()" *ngIf="!isLoggedIn()">
      <fa-icon class="d-flex icon align-self-center" [icon]="['fas', 'user-circle']"></fa-icon>
      <span class="align-self-center">{{'sidebar.login' | translate}}</span>
    </li>
    <li class="d-flex flex-row" (click)="logout()"  *ngIf="isLoggedIn()">
      <fa-icon class="d-flex icon align-self-center" [icon]="['fas', 'user-circle']"></fa-icon>
      <span class="align-self-center">{{'sidebar.logout' | translate}}</span>
    </li>
  </ul>
</div>
