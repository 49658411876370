import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {OPEN_DATA_URL} from '../../app.constants';
import {AccountService} from '../../core/auth/account.service';
import {LoginService} from '../../core/login/login.service';
import {Account} from '../../core/user/account.model';
import {MapMode} from '../../shared/map/map.component';
import {GlobalStateService} from '../../shared/service/global-state.service';

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['sidebar.scss']
})
export class SidebarComponent implements OnInit {

  account!: Account;

  constructor(private globalStateService: GlobalStateService,
              private loginService: LoginService,
              private accountService: AccountService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.getAccount();
  }

  getAccount(): void {
    this.accountService.identity().subscribe(account => {
      if (account) {
        this.account = account;
      }
    });
  }

  login(): void {
    this.loginService.login();
  }

  logout(): void {
    this.loginService.logout();
  }

  getOpenDataURl(): string {
    return OPEN_DATA_URL;
  }

  close(): void {
    this.globalStateService.close();
  }

  isLoggedIn(): boolean {
    return this.accountService.isAuthenticated();
  }

  showHistory(): void {
    this.globalStateService.setMapMode(MapMode.HISTORICAL);
  }

  openDocs(): void {
    this.globalStateService.close();
    this.router.navigate(['/admin/docs']);
  }

  openLandParcelsManagement(): void {
    this.globalStateService.close();
    this.router.navigate(['/admin/land-parcel-definitions']);
  }

  openBufferDefinitionsManagement(): void {
    this.globalStateService.close();
    this.router.navigate(['/admin/buffer-definitions']);
  }

  openLandParcelGeneration(): void {
    this.globalStateService.close();
    this.router.navigate(['/admin/land-parcel-generation']);
  }

    openCadastreUnitLandParcelGeneration(): void {
        this.globalStateService.close();
        this.router.navigate(['/admin/cadastre-unit-land-parcel-generation']);
    }

  openJobs(): void {
    this.globalStateService.close();
    this.router.navigate(['/admin/jobs']);
  }

}
