import {Component, Injectable} from '@angular/core';
import {
  NgbActiveModal,
  NgbCalendar,
  NgbDate,
  NgbDateParserFormatter,
  NgbDatepickerI18n,
  NgbDateStruct
} from "@ng-bootstrap/ng-bootstrap";
import {TranslateService} from "@ngx-translate/core";

const I18N_VALUES = {
    'et': {
        weekdays: ['E', 'T', 'K', 'N', 'R', 'L', 'P'],
        months: ['jaanuar', 'veebruar', 'märts', 'aprill', 'mai', 'juuni', 'juuli', 'august', 'september', 'oktoober', 'november', 'detsember'],
        weekLabel: 'nädal'
    }
};

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  readonly DELIMITER = '.';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10)
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? date.day + this.DELIMITER + (date.month < 10 ? '0' + date.month : date.month) + this.DELIMITER + date.year : '';
  }
}

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
    constructor(private _i18n: TranslateService) {
        super();
    }

    getWeekdayShortName(weekday: number): string {
        return I18N_VALUES[this._i18n.store.defaultLang].weekdays[weekday - 1];
    }

    getWeekLabel(): string {
        return I18N_VALUES[this._i18n.store.defaultLang].weekLabel;
    }

    getMonthShortName(month: number): string {
        return I18N_VALUES[this._i18n.store.defaultLang].months[month - 1];
    }

    getMonthFullName(month: number): string {
        return this.getMonthShortName(month);
    }

    getDayAriaLabel(date: NgbDateStruct): string {
        return `${date.day}.${date.month}.${date.year}`;
    }
}


@Component({
  selector: 'history-modal',
  templateUrl: './history-modal.component.html',
  providers: [
    {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}
  ]
})
export class HistoryModalComponent {

    formLeftDate: NgbDate | null;
    formRightDate: NgbDate | null;

    constructor(public activeModal: NgbActiveModal,
                public formatter: NgbDateParserFormatter,
                private calendar: NgbCalendar) {
        this.formLeftDate = this.calendar.getToday();
        this.formRightDate = this.calendar.getToday();

    }

    apply(): void {
        this.activeModal.close({leftDate: this.formLeftDate, rightDate: this.formRightDate});
    }

    validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
        const parsed = this.formatter.parse(input);
        return parsed ? NgbDate.from(parsed) : currentValue;
    }



}
