import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {TranslateModule} from '@ngx-translate/core';
import {faCalendar, faCalendarAlt, faClone, faMap} from '@fortawesome/free-regular-svg-icons';
import {
  faBars,
  faChevronCircleRight,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCode,
  faCog,
  faCrosshairs,
  faDownload,
  faExclamationTriangle,
  faFolderOpen,
  faHistory,
  faMapMarkerAlt,
  faPlus,
  faPrint,
  faRuler,
  faSearch,
  faShapes,
  faSpinner,
  faSyncAlt,
  faTimes,
  faUserCircle
} from '@fortawesome/free-solid-svg-icons';
import {NgxBootstrapSliderModule} from 'ngx-bootstrap-slider';
import {DragDropModule} from '@angular/cdk/drag-drop';


@NgModule({
  exports: [
    FormsModule,
    CommonModule,
    NgbModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    NgxBootstrapSliderModule,
    TranslateModule,
    DragDropModule
  ],
})
export class AppSharedLibsModule {
  constructor(private library: FaIconLibrary) {
    library.addIcons(faMap, faClone, faSyncAlt, faSearch, faCalendarAlt, faCalendar, faTimes, faBars, faChevronLeft,
      faHistory, faCog, faUserCircle, faCode, faShapes, faCrosshairs, faMapMarkerAlt, faRuler, faChevronDown,
      faChevronUp, faDownload, faFolderOpen, faChevronRight, faPlus, faChevronCircleRight, faPrint, faSpinner, faExclamationTriangle);
  }
}
