<div>
  <input id="inads-typeahead" type="text" class="form-control"
         [(ngModel)]="address"
         [resultTemplate]="resultTemplate"
         [ngbTypeahead]="search"
         [inputFormatter]="formatter"
         (ngModelChange)="onItemSelect($event)"
         placeholder="{{showPlaceHolder?('inads.search.placeholder'|translate):''}}"/>
  <div class="invalid-feedback" *ngIf="searchFailed">{{'inads.search.error'|translate}}</div>
</div>


<ng-template #resultTemplate let-r="result" let-t="term">
  <ngb-highlight [result]="r.ipikkaadress" [term]="t"></ngb-highlight>
  [
  <ngb-highlight [result]="r.tunnus" [term]="t"></ngb-highlight>
  ]
</ng-template>
