import {Injectable, TemplateRef} from '@angular/core';

const SUCCESS_OPTIONS = {classname: 'bg-success text-light', delay: 10000};

const ERROR_OPTIONS = {classname: 'bg-danger text-light', delay: 10000};

@Injectable({providedIn: 'root'})
export class ToastService {
    toasts: any[] = [];

    show(textOrTpl: string | TemplateRef<any>, options: any = {}): void {
        this.toasts.push({textOrTpl, ...options});
    }

    showSuccess(textOrTpl: string | TemplateRef<any>): void {
        this.toasts.push({textOrTpl, ...SUCCESS_OPTIONS});
    }

    showError(textOrTpl: string | TemplateRef<any>): void {
        this.toasts.push({textOrTpl, ...ERROR_OPTIONS});
    }

    remove(toast): void {
        this.toasts = this.toasts.filter(t => t !== toast);
    }
}
