<div>
  <ngb-alert [dismissible]="false"  *ngIf="isSafari()">
    <span [innerHTML]="'cadastre_unit_print.safari'| translate"></span>
  </ngb-alert>
  <div class="d-flex flex-column my-2 py-2">
    <label for="format" class="form-label">{{'cadastre_unit_print.page_size'| translate}}</label>
    <select class="form-select  mb-3" id="format" [(ngModel)]="format" (change)="setPrintSize()">
      <option value="A3">A3</option>
      <option value="A4" selected>A4</option>
    </select>
    <label for="orientation" class="form-label">{{'cadastre_unit_print.orientation'| translate}}</label>
    <select class="form-select  mb-3" id="orientation" [(ngModel)]="orientation" (change)="setPrintSize()">
      <option value="LANDSCAPE" selected>{{'cadastre_unit_print.orientation.landscape'| translate}}</option>
      <option value="PORTRAIT">{{'cadastre_unit_print.orientation.portrait'| translate}}</option>
    </select>

    <label for="scale" class="form-label">{{'cadastre_unit_print.scale'| translate}}</label>
    <select class="form-select mb-3" id="scale" [(ngModel)]="scale" (change)="setPrintSize()">
      <option [value]="initialScale" selected>{{initialScaleText}}</option>
      <option disabled>--</option>
      <option value="0.5">1 : 500</option>
      <option value="1">1 : 1000</option>
      <option value="2"> 1 : 2000</option>
      <option value="5">1 : 5000</option>
      <option value="10">1 : 10 000</option>
      <option value="20">1 : 20 000</option>
      <option value="50">1 : 50 000</option>
      <option value="100">1 : 100 000</option>
      <option value="150">1 : 150 000</option>
      <option value="200">1 : 200 000</option>
      <option value="300">1 : 300 000</option>
      <option value="500">1 : 500 000</option>
    </select>
    <div class="form-check">
      <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" [(ngModel)]="sign">
      <label for="scale" class="form-label">{{'cadastre_unit_print.sign'| translate}}</label>
    </div>
    <div class="d-flex flex-column flex-md-row w-100">
      <div class="d-flex w-100">
        <button type="button" class="btn btn-primary-blue w-100 mx-2" (click)="printToPdf()" [disabled]="!canPrint">
          <fa-icon [icon]="['fas', 'spinner']" *ngIf="!canPrint" [spin]="!canPrint" class="icon mr-2"></fa-icon>
          <span *ngIf="!canPrint" translate="button.wait"></span>
          <span *ngIf="canPrint" translate="button.print"></span>
        </button>
      </div>
    </div>
  </div>
</div>
