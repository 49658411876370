import {Vector as VectorLayer} from 'ol/layer';
import TileLayer from 'ol/layer/Tile';
import {Extent} from 'ol/extent';

export const MAP_EXTEND_EST: Extent = [40500, 5993000, 1064500, 7017000];
export const RESOLUTIONS_EST: number[] = [4000, 2000, 1000, 500, 250, 125, 62.5, 31.25, 15.625, 7.8125, 3.90625, 1.953125, 0.9765625, 0.48828125, 0.244140625, 0.1220703125];
export const MAX_ZOOM = 16;

export enum MapLayerType {
  BASE,
  FEATURE_LAYER,
  SELECT_LAYER,
  MEASURING_LAYER,
}

export class MapLayer {

  private visible = false;

  private opacity: number;

  private _historical = false;

  constructor(
    public id: string,
    public layers: Array<VectorLayer | TileLayer> = [],
    public layerType = MapLayerType.BASE,
    public thumbnailUrl: string | null = null,
    public childMapLayers: Array<MapLayer> = [],
  ) {
    if (layers.length !== 0) {
      this.visible = layers[0].getVisible();
    }
  }

  public getVisible(): boolean {
    return this.visible;
  }

  public setVisible(visibility: boolean): void {
    this.visible = visibility;
    this.layers.forEach(layer => layer.setVisible(visibility));
    if (this.childMapLayers.length !== 0) {
      this.childMapLayers.forEach((child) => child.setVisible(visibility));
    }
  }

  public getOpacity(): number {
    return this.opacity;
  }

  public setOpacity(opacity: number) {
    this.opacity = opacity;
    this.layers.forEach(layer => layer.setOpacity(opacity));
    if (this.childMapLayers.length !== 0) {
      this.childMapLayers.forEach((child) => child.setOpacity(opacity));
    }
  }

  get historical(): boolean {
    return this._historical;
  }

  set historical(value: boolean) {
    this._historical = value;
  }
}
