import {Injectable} from '@angular/core';
import {MAP_EXTEND_EST, MapLayer, MapLayerType, RESOLUTIONS_EST} from './map.layers';
import TileLayer from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';
import XYZ from 'ol/source/XYZ';
import TileGrid from 'ol/tilegrid/TileGrid';
import {TranslateService} from '@ngx-translate/core';
import {MapConfigService} from '../../api/land-parcel-api/service/map-config.service';
import {MapComponent} from './map.component';
import {FeatureLayerConfig} from '../../api/land-parcel-api/model/feature-layer-config.model';
import {Fill, Stroke, Style} from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import {JhiEventManager} from 'ng-jhipster';
import {MAP_HISTORY_CHANGE_EVENT} from './map.model';
import {SwipeListComponent} from '../list/swipe-list.component';
import {Subscription} from 'rxjs';
import {MediaObserverService} from '../service/media-observer.service';
import {measuringStyle} from "./styles";
import Map from "ol/Map";
import {Coordinate} from "ol/coordinate";


@Injectable()
export class MapService {
  readonly DT_FORMAT = 'YYYY-MM-DD';


  private swipeListEvent: Subscription;

  constructor(
    protected mapComponent: MapComponent,
    private mapConfigService: MapConfigService,
    private translateService: TranslateService,
    private eventManager: JhiEventManager,
    private mediaObserverService: MediaObserverService
  ) {
  }

  initMap(mapComponent: MapComponent): void {
    this.mapComponent = mapComponent;
    this.mapConfigService.getMapBaseLayers('response').subscribe((res) => {
      const baseLayers: MapLayer[] = [];
      res.body?.sort((a, b) => (a.orderNr as number) - (b.orderNr as number)).forEach((layerConfig) => {
        const attributionText = this.translateService.instant('map.attribution-prefix')
                              + this.translateService.instant(layerConfig.id as string).toLowerCase();
        const theLayer = new MapLayer(
          layerConfig.id as string,
          [new TileLayer({
            source: new TileWMS({
              url: layerConfig.wmsInfo?.url,
              attributions: [attributionText],
              crossOrigin: 'Anonymous',
              params: {
                LAYERS: layerConfig.wmsInfo?.layers,
                FORMAT: 'image/png',
                VERSION: '1.1.1',
                TILED: true
              }
            }),
            visible: layerConfig.defaultLayer,
            minZoom: layerConfig.tmsToWmsZoom ? layerConfig.tmsToWmsZoom : -Infinity
          })],
          MapLayerType.BASE,
          layerConfig.thumbnailUrl
        );
        if (layerConfig.tmsToWmsZoom) {
          layerConfig.tmsInfo?.layerUrls?.forEach((tmsUrl) => {
            const mapTileGrid: TileGrid = new TileGrid({
              extent: MAP_EXTEND_EST,
              resolutions: RESOLUTIONS_EST
            });
            const tmsLayer = new TileLayer({
              source: new XYZ({
                attributions: [attributionText],
                projection: 'EPSG:3301',
                tileGrid: mapTileGrid,
                crossOrigin: 'Anonymous',
                url: tmsUrl + '/{z}/{x}/{-y}.png'
              }),
              visible: layerConfig.defaultLayer,
              maxZoom: layerConfig.tmsToWmsZoom
            });
            theLayer.layers.push(tmsLayer);
          });
        }
        baseLayers.push(theLayer);
      });
      this.mapComponent.init(baseLayers);
      this.loadFeatureLayers();
    });
  }

  getMap(): Map | null {
    return this.mapComponent.map;
  }

  getMapComponent(): MapComponent {
    return this.mapComponent;
  }

  loadFeatureLayers(): void {
    this.mapConfigService.getMapFeatureLayers('response').subscribe((res) => {
      res.body?.forEach((layerConfig) => {
        this.mapComponent.addFeatureLayer(this.createFeatureLayer(layerConfig));
      });
      this.addSelectLayer();
      this.addETAKLayer();
      this.addMeasuringLayer();
      // this.applyHistoryToLandParcelLayer(LAND_PARCELS, this.getNow());
      this.registerSwipeListStateChange();
    });
  }

  private registerSwipeListStateChange(): void {
    this.swipeListEvent = this.eventManager.subscribe(SwipeListComponent.EVENT_SWIPE_LIST_STATE_CHANGE, (result: any) => {
      if (result.content && this.mediaObserverService.isMobile()) {
        const options = {
          padding: [110, 5, result.content.height, 5],
          duration: result.content.transition ? 500 : 0
        };
        this.mapComponent.setViewOptions(options);
        this.mapComponent.moveMapLayerSelectButton({ bottom: result.content.height }, result.content.transition);

        if (result.content.dragEnd) {
          const activeLayerName = this.mapComponent.getActiveLayerName();
          let activeLayer = this.mapComponent.getMapLayer(activeLayerName);
          let searchCounter = 0;
          if (!activeLayer) {
            const searchInterval = setInterval(() => {
              if (!activeLayer && searchCounter < 5) {
                activeLayer = this.mapComponent.getMapLayer(activeLayerName);
                searchCounter++;
              } else {
                clearInterval(searchInterval);
              }
            }, 5);
          }
          if (activeLayer) {
            this.mapComponent.fitFeatureWithPadding([110, 5, result.content.height, 5]);
          }
        }
      }
    });
  }

  getNow(): NgbDateStruct {
    const date = new Date();
    return {day: date.getUTCDate(), month: date.getUTCMonth() + 1, year: date.getUTCFullYear()};
  }

  createFeatureLayer(layerConfig: FeatureLayerConfig): MapLayer {
    const featureLayer = new MapLayer(layerConfig.id as string, [], MapLayerType.FEATURE_LAYER);
    featureLayer.historical = !!layerConfig.historical;
    if (layerConfig.url) {
      const theLayer = new TileLayer({
        source: new TileWMS({
          url: layerConfig.url,
          params: {
            LAYERS: [layerConfig.layerName],
            FORMAT: 'image/png',
            VERSION: '1.1.1',
            TILED: true,
          },
        }),
        minZoom: layerConfig.tmsToWmsZoom ? layerConfig.tmsToWmsZoom : -Infinity,
        visible: layerConfig.visible,
      });
      featureLayer.layers = [theLayer];
    }
    if (layerConfig.tmsToWmsZoom) {
      layerConfig.tmsInfo?.layerUrls?.forEach((tmsUrl) => {
        const mapTileGrid: TileGrid = new TileGrid({
          extent: MAP_EXTEND_EST,
          resolutions: RESOLUTIONS_EST
        });
        const tmsLayer = new TileLayer({
          source: new XYZ({
            projection: 'EPSG:3301',
            tileGrid: mapTileGrid,
            crossOrigin: 'Anonymous',
            url: tmsUrl + '/{z}/{x}/{-y}.png'
          }),
          visible: true,
          maxZoom: layerConfig.tmsToWmsZoom
        });
        featureLayer.layers.push(tmsLayer);
      });
    }

    featureLayer.setVisible(layerConfig.visible)
    featureLayer.setOpacity(layerConfig.opacity);
    return featureLayer;
  }

  flyToCadaster(x: number, y: number): void {
    this.eventManager.broadcast(MAP_HISTORY_CHANGE_EVENT);
    this.mapComponent.view.animate({
      center: [x, y],
      zoom: 14,
      duration: 2000,
    });
  }

  showCadastreUnit(event: any, coordinate: Coordinate) {
    this.mapComponent.requestCadastreUnitInfo(event,coordinate);
  }

  private addSelectLayer(): void {
    const vectorLayer = new VectorLayer({
      source: new VectorSource({wrapX: false}),
      style: new Style({
        stroke: new Stroke({
          color: 'rgb(0,110,181)',
          width: 3,
          lineDash: [5, 8],
        }),
        fill: new Fill({
          color: 'rgb(232,246,254, 0.5)'
        }),
      }),
      visible: true
    });
    const selectLayer = new MapLayer('SELECT_LAYER', [vectorLayer], MapLayerType.SELECT_LAYER);
    this.mapComponent.addFeatureLayer(selectLayer);
  }

  //TODO: remove after. duplicated in map component
  public applyHistoryToLandParcelLayer(layerName: string, date: NgbDateStruct): void {
    const historyString = `(valid_from BEFORE ${this.format(date)}T06:00:00.000000 OR valid_from IS NULL)  AND (valid_to AFTER ${this.format(date)}T06:00:00.000000 OR valid_to IS NULL)`;
    const source = this.getLandParcelsLayer(layerName).layers[0].getSource() as TileWMS;
    source.updateParams({CQL_FILTER: historyString});
    this.eventManager.broadcast(MAP_HISTORY_CHANGE_EVENT);
  }

  private addETAKLayer(): void {
    const vectorLayer = new VectorLayer({
      source: new VectorSource({wrapX: false}),
      style: new Style({
        stroke: new Stroke({
          color: 'rgb(181,54,38)',
          width: 3,
          lineDash: [5, 8],
        }),
        fill: new Fill({
          color: 'rgb(181,54,38, 0.5)'
        }),
      }),
      visible: true
    });
    const etakLayer = new MapLayer('ETAK_LAYER', [vectorLayer], MapLayerType.SELECT_LAYER);
    this.mapComponent.addFeatureLayer(etakLayer);
  }

  private addMeasuringLayer(): void {
    const vectorLayer = new VectorLayer({
      source: new VectorSource({wrapX: false}),
      style: measuringStyle,
      visible: true
    });
    const measureLayer = new MapLayer('MEASURING_LAYER', [vectorLayer], MapLayerType.MEASURING_LAYER);
    this.mapComponent.addFeatureLayer(measureLayer);
  }


  getLandParcelsLayer(layerName: string): MapLayer {
    return this.mapComponent.getFeatureLayers().filter(layer => layer.id === layerName)[0];
  }

  format(date: NgbDateStruct): string {
    if (!date) {
      return '';
    }
    const mdt = moment([date.year, date.month - 1, date.day]);
    if (!mdt.isValid()) {
      return '';
    }
    return mdt.format(this.DT_FORMAT);
  }

  addFeatures(geometry: any, layer: string, optOptions?: any): void {
    const options = optOptions || {};
    this.mapComponent.addFeatures(geometry, layer, options);
  }

  clearLayer(layer: string): void {
    this.mapComponent.clearFeatures(layer);
  }

  moveMapLayerSelectButton(customStyle: any, withTransition: string): void {
    this.mapComponent.moveMapLayerSelectButton(customStyle, withTransition);
  }
}
