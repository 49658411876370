import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppSharedModule} from './shared/shared.module';
import {AppCoreModule} from './core/core.module';
import {AppHomeModule} from './home/home.module';
import {AppComponent} from './app.component';
import {MainComponent} from './layouts/main/main.component';
import {SidebarModule} from 'ng-sidebar';
import {SidebarComponent} from './layouts/sidebar/sidebar.component';

@NgModule({
  declarations: [
    AppComponent, MainComponent, SidebarComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AppSharedModule,
    AppCoreModule,
    AppHomeModule,
    SidebarModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
