import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NgbDateStruct, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {JhiEventManager} from 'ng-jhipster';
import {Subscription} from 'rxjs';
import {LandParcel} from '../../api/land-parcel-api/model/land-parcel.model';
import {TopologyObject} from '../../api/land-parcel-api/model/topology-object.model';
import {LandParcelService} from '../../api/land-parcel-api/service/land-parcel.service';
import {DownloadModalComponent} from '../download-modal-component/download-modal.component';
import {InfoRequestType, MapMode} from '../map/map.component';
import {
  MAP_CLICK_EVENT,
  MAP_DESKTOP_PADDING,
  MAP_HISTORY_CHANGE_EVENT,
  SHOW_LAND_PARCEL_DETAILS,
  SHOW_LAST_CADASTRE_UNIT
} from '../map/map.model';
import {MapService} from '../map/map.service';
import {AreaUnit, GlobalStateService, MapClickedSide} from '../service/global-state.service';
import {MediaObserverService} from '../service/media-observer.service';
import {ETAK_REF_URL} from "../../app.constants";

@Component({
    selector: 'app-kolvik',
    templateUrl: './kolvik.component.html',
    styleUrls: ['kolvik.component.scss'],
})
export class KolvikComponent implements OnInit, OnDestroy {

    selectedDate: NgbDateStruct;

    @Input()
    set historyDate(date: NgbDateStruct) {
        this.selectedDate = date;
    }

    kolvik: LandParcel;
    etakObjects: TopologyObject[];
    activeObject: TopologyObject;

    currentPage = 1;
    collectionSize = 1;

    mapClickListener: Subscription;
    showLandParcelListener: Subscription;
    historyChangeListener: Subscription;

    areaUnit: AreaUnit;

    showBackButton = false;

    constructor(
      private mapService: MapService,
      private eventManager: JhiEventManager,
      private landParcelService: LandParcelService,
      private mediaObserverService: MediaObserverService,
      private globalStateService: GlobalStateService,
      private modalService: NgbModal
    ) {
    }

    ngOnInit(): void {
        this.subscribeToMap();
        this.globalStateService.getAreaUnit().subscribe(value => {
            this.areaUnit = value;
        });
    }

    private subscribeToMap(): void {
        const options = {
            padding: this.mediaObserverService.isMobile() ? [150, 5, 150, 5] : MAP_DESKTOP_PADDING,
            duration: 500
        };
        this.mapClickListener = this.eventManager.subscribe(MAP_CLICK_EVENT, event => {

            if (event.content.requestType === InfoRequestType.LAND_PARCEL) {
                this.clearSelection();
                const dateString = this.globalStateService.getHistoricalStateValue().side === MapClickedSide.LEFT ?
                  this.globalStateService.getHistoricalStateValue().leftDate : this.globalStateService.getHistoricalStateValue().rightDate;
                const filter = {
                    x: event.content.coordinate[0],
                    y: event.content.coordinate[1],
                  date: dateString
                };
                if (dateString) {
                    filter.date = dateString;
                }

                this.landParcelService.findLandParcel(filter.date, filter.x, filter.y, null, 'body').subscribe(response => {
                    if (response) {
                      const p = response[0];
                      this.kolvik = p;
                      this.mapService.addFeatures(p.geometry, 'SELECT_LAYER', options);
                      this.currentPage = 1;
                      this.getTopologyObjects(p.id, this.currentPage);
                    }
                });
            } else {
        this.kolvik = undefined;
      }
    });

        this.showLandParcelListener = this.eventManager.subscribe(SHOW_LAND_PARCEL_DETAILS, event => {
      if (event.content.resp) {
          this.showBackButton = true;
          this.kolvik = event.content.resp;
          this.mapService.addFeatures(event.content.resp.geometry, 'SELECT_LAYER', options);
          this.currentPage = 1;
          this.getTopologyObjects(event.content.resp.id, this.currentPage);
      }
    });

        this.historyChangeListener = this.eventManager.subscribe(MAP_HISTORY_CHANGE_EVENT, () => {
      this.clearSelection();
    });
  }

  pageChangeEvent(pageNumber): void {
    this.getTopologyObjects(this.kolvik.id, pageNumber);
  }

  getTopologyObjects(id: number, pageNumber: number): void {
    this.landParcelService.getLandParcelTopologyObjects(id, 10, pageNumber - 1, 'body').subscribe(resp => {
      this.etakObjects = resp.topologyObjects;
      this.collectionSize = resp.totalElements;
    });
  }

  clearSelection(): void {
      this.kolvik = null;
      this.mapService.clearLayer('SELECT_LAYER');
      this.mapService.clearLayer('ETAK_LAYER');
      this.mapService.moveMapLayerSelectButton({bottom: 0}, '');
  }

    getAreaString(area: number): string {
        return area.toFixed(this.areaUnit === AreaUnit.HA ? 2 : 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }

    getAreUnitText(value: AreaUnit): string {
        if (value === AreaUnit.M2) {
            return ' m<sup>2</sup>';
        }
        if (value === AreaUnit.HA) {
            return ' ha';
        }
        return '';
    }

    isHa(): boolean {
        return this.areaUnit === AreaUnit.HA;
    }

    isM2(): boolean {
        return this.areaUnit === AreaUnit.M2;
    }

    isMobile(): boolean {
        return this.mediaObserverService.isMobile();
    }

  showFeature(obj: TopologyObject): void {
    this.mapService.clearLayer('ETAK_LAYER');
    if (this.activeObject !== obj) {
      this.activeObject = obj;
      this.mapService.addFeatures(obj.geometry, 'ETAK_LAYER');
    } else {
      this.activeObject = null;
    }
  }

  ngOnDestroy(): void {
    this.mapClickListener.unsubscribe();
    this.historyChangeListener.unsubscribe();
    this.showLandParcelListener.unsubscribe();
  }

  setUnit(): void {
    if (this.areaUnit === AreaUnit.M2) {
      this.globalStateService.setAreaUnit(AreaUnit.HA);
    } else {
      this.globalStateService.setAreaUnit(AreaUnit.M2);
    }
  }

  showHistory(): void {
    this.globalStateService.setMapMode(MapMode.HISTORICAL);
  }

  back(): void {
    this.kolvik = undefined;
    this.clearSelection();
    this.eventManager.broadcast(SHOW_LAST_CADASTRE_UNIT);
  }

  download() {
    const modalRef = this.modalService.open(DownloadModalComponent);
    modalRef.componentInstance.type = InfoRequestType.LAND_PARCEL;
    modalRef.componentInstance.kolvikId = this.kolvik.id;
  }

  getEtakRefURl(etakId: string): string {
    return ETAK_REF_URL + etakId;
  }
}
