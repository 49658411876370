import {Route, Routes} from '@angular/router';

import {HomeComponent} from './home.component';

export const HOME_ROUTES: Routes = [{
  path: '',
  component: HomeComponent,
  data: {
    authorities: [],
    pageTitle: 'home.title',
  }
},
  {
    path: 'cadastre/:cadastreCode',
    component: HomeComponent,
    data: {
      authorities: [],
      pageTitle: 'home.title',
    }
  }];
