/**
 * landuse
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ExportFormat = 'GML_2' | 'GML_3_1' | 'GML_3_2' | 'CSV' | 'GEOJSON' | 'KML' | 'SHAPEFILE';

export const ExportFormat = {
    GML2: 'GML_2' as ExportFormat,
    GML31: 'GML_3_1' as ExportFormat,
    GML32: 'GML_3_2' as ExportFormat,
    CSV: 'CSV' as ExportFormat,
    GEOJSON: 'GEOJSON' as ExportFormat,
    KML: 'KML' as ExportFormat,
    SHAPEFILE: 'SHAPEFILE' as ExportFormat
};

