import Style from 'ol/style/Style';
import {Circle, Fill} from 'ol/style';
import Stroke from 'ol/style/Stroke';
import CircleStyle from "ol/style/Circle";

export const defaultStroke = new Stroke({
  color: 'rgb(0,110,181)',
  width: 2,
  lineDash: [5, 8],
});


export const defaultFill = new Fill({
    color: 'rgba(232,246,254,0.5)'
  }
)

export const defaultStyle = [
  new Style({
    stroke: defaultStroke,
    fill: defaultFill,
  }),
  new Style({
    image: new Circle({
      fill: defaultFill,
      stroke: defaultStroke,
      radius: 10
    }),
    fill: defaultFill,
    stroke: defaultStroke,
  })]

export const measuringStyle = [
  new Style({
    fill: defaultFill,
    stroke: defaultStroke,
    image: new CircleStyle({
      radius: 7,
      fill: defaultFill,
    }),
  }),
]
