/**
 * landuse
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PaperFormat = 'A4' | 'A3';

export const PaperFormat = {
    A4: 'A4' as PaperFormat,
    A3: 'A3' as PaperFormat
};

