import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LoaderService} from '../../shared/service/loader.service';

export const excludeRequests: string[] = [];

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  activeRequests = 0;

  constructor(private loaderService: LoaderService) {
  }

  isValidRequestForInterceptor(requestUrl: string): boolean {
    for (const address of excludeRequests) {
      if (new RegExp(address).test(requestUrl)) {
        return false;
      }
    }

    return true;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isValidRequestForInterceptor(request.url)) {
      return next.handle(request).pipe(finalize(() => {
      }));
    }
    if (this.activeRequests === 0) {
      this.loaderService.showLoader();
    }

    this.activeRequests++;

    return next.handle(request).pipe(
      finalize(() => {
        this.activeRequests--;
        if (this.activeRequests === 0) {
          this.loaderService.hideLoader();
        }
      })
    );
  }
}
