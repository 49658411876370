import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Configuration as InAdsConfiguration} from '../../app/api/inads/configuration';
import {Configuration as MapApiConfiguration} from '../api/land-parcel-api/configuration';
import en from '../i18n/en';
import et from '../i18n/et';
import {HasAnyAuthorityDirective} from './auth/has-any-authority.directive';
import {CadastreUnitComponent} from './cadastre-unit/cadastre-unit.component';
import {DownloadModalComponent} from './download-modal-component/download-modal.component';
import {HistoryModalComponent} from './history-modal-component/history-modal.component';
import {InadsComponent} from './inads/inads.component';
import {KolvikComponent} from './kolvik/kolvik.component';
import {SwipeListComponent} from './list/swipe-list.component';
import {MapWrapperComponent} from './map-wrapper/map-wrapper.component';
import {MapComponent} from './map/map.component';
import {MapService} from './map/map.service';
import {AreaUnitPipe} from './pipe/area-unit.pipe';
import {GlobalStateService} from './service/global-state.service';
import {LoaderService} from './service/loader.service';
import {MediaObserverService} from './service/media-observer.service';
import {AppSharedLibsModule} from './shared-libs.module';
import {ToastsContainer} from './toast/toasts-container.component';
import {MapPrintingComponent} from "./map-printing/map-printing.component";

@NgModule({
  imports: [AppSharedLibsModule, RouterModule],
  declarations: [
    MapComponent,
    MapWrapperComponent,
    InadsComponent,
    KolvikComponent,
    SwipeListComponent,
    AreaUnitPipe,
    HasAnyAuthorityDirective,
    CadastreUnitComponent,
    HistoryModalComponent,
    DownloadModalComponent,
    ToastsContainer,
    MapPrintingComponent
  ],
  exports: [
    AppSharedLibsModule,
    MapComponent,
    MapWrapperComponent,
    InadsComponent,
    KolvikComponent,
    SwipeListComponent,
    AreaUnitPipe,
    HasAnyAuthorityDirective,
    CadastreUnitComponent,
    HistoryModalComponent,
    DownloadModalComponent,
    ToastsContainer,
    DownloadModalComponent,
    MapPrintingComponent
  ],
  providers: [MapService, MapComponent, LoaderService, MediaObserverService, GlobalStateService,
    {
      provide: MapApiConfiguration,
      useValue: new MapApiConfiguration({basePath: '/api'}),
    }, {
      provide: InAdsConfiguration,
      useValue: new InAdsConfiguration({basePath: 'https://inaadress.maaamet.ee'}),
    }, ]
})
export class AppSharedModule {

  constructor(translate: TranslateService) {
    translate.setTranslation('et', et, true);
    translate.setTranslation('en', en, true);
  }

}
