import {NgModule} from '@angular/core';
import {DatePipe, registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {TranslateModule} from '@ngx-translate/core';
import locale from '@angular/common/locales/et';
import {LoadingInterceptor} from './blocks/loading.interceptor';
import {AuthExpiredInterceptor} from './blocks/interceptor/auth-expired.interceptor';
import {NgxWebstorageModule} from 'ngx-webstorage';

@NgModule({
  imports: [
    HttpClientModule,
    TranslateModule.forRoot({defaultLanguage: 'et'}),
    NgxWebstorageModule.forRoot({}),
  ],
  providers: [
    Title,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthExpiredInterceptor,
      multi: true,
    },
  ],
})
export class AppCoreModule {
  constructor() {
    registerLocaleData(locale);
  }
}
