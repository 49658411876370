<ng-sidebar-container class="sidebar-container">

  <ng-sidebar [(opened)]="sidebarOpened"
              mode="{{mode}}"
              [dock]="dock"
              [showBackdrop]="showBackdrop"
              [closeOnClickBackdrop]="closeOnClickBackdrop"
              [closeOnClickOutside]="closeOnClickOutside">
    <router-outlet name="sidebar"></router-outlet>
  </ng-sidebar>


  <div ng-sidebar-content class="h-100">
    <div class="d-flex flex-row">
      <div class="side-bar-icon-wrapper d-flex justify-content-center bg-primary" (click)="toggleSidebar()"
           id="sidebar-toggle">
        <fa-icon class="d-flex text-white align-self-center" [icon]="['fas', 'bars']"></fa-icon>
      </div>

      <nav class="navbar navbar-expand-lg navbar-light bg-white">
        <div class="d-flex flex-row justify-content-between w-100">
          <div class="d-flex">
            <h5 class="font-weight-normal text-primary m-0 align-self-center">{{'app.title' | translate}}</h5>
          </div>
        </div>
      </nav>
    </div>

    <div>
      <app-toasts class="position-absolute mr-4"></app-toasts>

      <main role="main">
        <router-outlet></router-outlet>
      </main>
      <router-outlet name="popup"></router-outlet>
    </div>

    <div class="loader-wrapper" *ngIf="showLoader">
      <div class="progress progress-striped">
        <div class="push"></div>
        <div class="progress-bar"></div>
      </div>
    </div>
  </div>
</ng-sidebar-container>
