import {AfterViewInit, Component, Injectable} from '@angular/core';
import {NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {InAdsAddress} from '../api/inads/model/in-ads-address.model';
import {MapService} from '../shared/map/map.service';
import {InfoRequestType, LAND_PARCELS_CURRENT} from "../shared/map/map.component";
import {ActivatedRoute} from "@angular/router";
import {MAP_CLICK_EVENT} from "../shared/map/map.model";
import {JhiEventManager} from "ng-jhipster";
import * as moment from "moment/moment";

@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {

  readonly DELIMITER = '.';

  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10)
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : null;
  }
}

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  readonly DELIMITER = '.';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10)
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : '';
  }
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  providers: [
    {provide: NgbDateAdapter, useClass: CustomAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}
  ],
  styleUrls: ['home.scss'],
})
export class HomeComponent implements AfterViewInit {
  activeTab = 'inads';
  historyDate: NgbDateStruct;
  originalDate: NgbDateStruct;

  constructor(private mapService: MapService,
              private activatedRoute: ActivatedRoute,
              private eventManager: JhiEventManager) {
  }

  setTab(type: any): void {
    this.activeTab = type;
  }

  loadGeometry(address: InAdsAddress): void {
    if (address && address.tunnus && address.viitepunkt_x && address.viitepunkt_y) {
      this.mapService.showCadastreUnit(address, [address.viitepunkt_x, address.viitepunkt_y]);
    }
  }

  dateSelect(date: NgbDateStruct): void {
    this.originalDate = date;
    this.mapService.applyHistoryToLandParcelLayer(LAND_PARCELS_CURRENT, date); //REPLACE
  }

  ngOnInit(): void {

  }

  broadcastInfoRequest(coordinate, requestType: InfoRequestType, cadastreCode: string | undefined) {
    this.eventManager.broadcast({
      name: MAP_CLICK_EVENT,
      content: {requestType, coordinate, cadastreCode, date:moment().format('YYYY-MM-DD')}
    });

  }

  ngAfterViewInit(): void {
    this.activatedRoute.params.subscribe(params => {
      const cadastreCode = params['cadastreCode'];
      if (cadastreCode) {
        this.broadcastInfoRequest([0,0], InfoRequestType.CADASTRE_UNIT, cadastreCode);
      }
    });
  }
}
