import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MapService} from '../map/map.service';
import {LoaderService} from '../service/loader.service';
import {ScaleLine} from 'ol/control';
import {getPointResolution} from 'ol/proj';
import * as domtoimage from 'dom-to-image-more';
import {MapPrintService} from '../../api/land-parcel-api/service/map-print.service';
import {MapPrint} from '../../api/land-parcel-api/model/map-print.model';
import {PaperOrientation} from '../../api/land-parcel-api/model/paper-orientation.model';
import {PrintType} from '../../api/land-parcel-api/model/print-type.model';
import {PaperFormat} from '../../api/land-parcel-api/model/paper-format.model';
import * as moment from 'moment';
import {AreaUnit, GlobalStateService} from "../service/global-state.service";
import {Platform} from "@angular/cdk/platform";
import {JhiEventManager} from "ng-jhipster";

@Component({
  selector: 'jhi-map-printing',
  templateUrl: './map-printing.component.html',
  styleUrls: ['map-printing.component.scss']
})
export class MapPrintingComponent implements OnInit, OnDestroy {

  @Input() context: any;

  resolution = 50;
  printResolution = 200;
  format: PaperFormat = PaperFormat.A4;
  scale = 1000;
  initialScale = 0;
  initialScaleText = '';
  orientation: PaperOrientation = PaperOrientation.LANDSCAPE;
  scaleResolution = 1;

  sign = false;
  code = '';
  printType = PrintType.BASIC;
  areaUnit = AreaUnit.M2
  date = undefined;

  margins = {
    landscape_margin_x: 10,
    landscape_margin_y: 20,
    portrait_margin_x: 10,
    portrait_margin_y: 20,
  }

  dims = {};

  tmpWidth = '';
  tmpHeight = '';
  tmpViewResolution = 0;

  exportOptions = {
    filter: (element) => {


      const className = element?.className || '';

      if (typeof className === 'string' && element?.parentElement?.className?.indexOf('ol-attribution') > -1) {
        return true;
      }

      const notAllowedTags: string[] = ['ul','input'];
      if (notAllowedTags.includes(element?.tagName?.toLowerCase())) {
        return false;
      }
      const notAllowedId: string[] = ['mouse-position'];
      if (notAllowedId.includes(element?.id!)) {
        return false;
      }
      return true;
    },
    width: 0,
    height: 0,
    bgcolor: 'white'
  };

  canPrint = false;

  constructor(
    protected globalStateService: GlobalStateService,
    private mapService: MapService,
    private loaderService: LoaderService,
    private mapPrintService: MapPrintService,
    private jhiEventManager: JhiEventManager,
    private platform: Platform
  ) {
  }

  ngOnDestroy(): void {
    this.globalStateService.hidePrintPreview();
    this.resetOriginalMapSize();
  }

  ngOnInit(): void {
    if (this.context) {
      const content = this.context.content;
      this.printType = content.printType;
      this.areaUnit = content.areaUnit;
      this.code = content.code;
      this.date = content.date;
    }

    this.setMapDpi(this.resolution);

    this.globalStateService.showPrintPreview();
    this.calculateDims();
    this.getInitialScale();
    this.scale = this.initialScale;
    this.setPrintSize();
    this.loaderService.getLoader().subscribe(value =>
      this.canPrint = !value)

    this.mapService.getMap().on('moveend', () => {
      this.getInitialScale();
      this.scale = this.initialScale;
    });

  }


  printToPdf() {
    this.globalStateService.showBackdrop();

    let map = this.mapService.getMap();

    this.setPrintSize(true);

    const dim = this.dims[`${this.format.toLocaleLowerCase()}_${this.orientation.toLocaleLowerCase()}`];
    const width = Math.round((dim[0] * this.printResolution) / 25.4);
    const height = Math.round((dim[1] * this.printResolution) / 25.4);

    this.exportOptions.width = width;
    this.exportOptions.height = height;

    const exportOptions = this.exportOptions

    const payload = this.getMapPrintRequestBody();
    if (this.date) {
      payload.date = this.date;
    }
    const mapTarget = document.getElementById('map');

    map.updateSize();
    map.render();

    map.once('rendercomplete', () => {
      domtoimage.toJpeg(mapTarget, exportOptions).then((dataUrl) => {
        payload.image = dataUrl;
        payload.dimension = {
          width,
          height,
          marginX: this.margins[`${this.orientation.toLocaleLowerCase()}_margin_x`],
          marginY: this.margins[`${this.orientation.toLocaleLowerCase()}_margin_y`]
        }
        this.resetOriginalMapSize();
        this.setMapDpi(this.resolution);
        this.downloadPdf(payload);
        this.globalStateService.hideBackdrop();
      });
    })
  }


  setPrintSize(print?: boolean): void {
    this.updateSwipePosition();

    this.calculateDims();

    const map = this.mapService.getMap();
    const dim = this.dims[`${this.format.toLocaleLowerCase()}_${this.orientation.toLocaleLowerCase()}`];
    const width = Math.round((dim[0] * (print ? this.printResolution : this.resolution)) / 25.4);
    const height = Math.round((dim[1] * (print ? this.printResolution : this.resolution)) / 25.4);

    const scaleResolution = this.scale /
      getPointResolution(this.mapService.getMap().getView().getProjection(),
        (print ? this.printResolution : this.resolution) / 25.4,
        this.mapService.getMap().getView().getCenter()
      );

    this.globalStateService.updatePrintPreviewDim([width, height]);

    //save original values
    this.tmpWidth = map.getTargetElement().style.width;
    this.tmpHeight = map.getTargetElement().style.height;
    this.tmpViewResolution = scaleResolution;

    map.getView().setResolution(scaleResolution);

    if (print) {
      map.getView().setResolution(scaleResolution);
      this.setMapDpi(this.printResolution);
      map.getTargetElement().style.width = width + 'px';
      map.getTargetElement().style.height = height + 'px';
    }
    map.updateSize();
    map.render();
    this.updateSwipePosition();
  }


  resetOriginalMapSize() {
    // Reset original map size
    const dim = this.dims[`${this.format.toLocaleLowerCase()}_${this.orientation.toLocaleLowerCase()}`];
    const width = Math.round((dim[0] * this.resolution) / 25.4);
    const height = Math.round((dim[1] * this.resolution) / 25.4);

    this.globalStateService.updatePrintPreviewDim([width, height]);
    const map = this.mapService.getMap();
    this.updateSwipePosition();
    this.setMapDpi(this.resolution);
    map.getTargetElement().style.width = '';
    map.getTargetElement().style.height = '';
    map.updateSize();
    map.getView().setResolution(this.tmpViewResolution);
    map.render();
  }


  getInitialScale(): void {
    const elements: HTMLCollectionOf<Element> = document.getElementsByClassName('ol-scale-text');
    this.initialScaleText = elements[0].textContent.replace(',', '');
    this.initialScale = +this.initialScaleText.split(':')[1] / 1000
  }

  calculateDims() {
    this.dims = {
      a3_landscape: [420 - (this.margins[`${this.orientation.toLocaleLowerCase()}_margin_x`] * 2), 297 - (this.margins[`${this.orientation.toLocaleLowerCase()}_margin_y`] * 2)],
      a3_portrait: [297 - (this.margins[`${this.orientation.toLocaleLowerCase()}_margin_x`] * 2), 420 - (this.margins[`${this.orientation.toLocaleLowerCase()}_margin_y`] * 2)],
      a4_landscape: [297 - (this.margins[`${this.orientation.toLocaleLowerCase()}_margin_x`] * 2), 210 - (this.margins[`${this.orientation.toLocaleLowerCase()}_margin_y`] * 2)],
      a4_portrait: [210 - (this.margins[`${this.orientation.toLocaleLowerCase()}_margin_x`] * 2), 297 - (this.margins[`${this.orientation.toLocaleLowerCase()}_margin_y`] * 2)]
    };
  }

  downloadPdf(payload: MapPrint) {
    this.mapPrintService.printMap(payload)
      .subscribe((response: any) => {
        this.saveFile(response, this.getFileName(this.printType, this.format));
      }, error => {
      })
  }

  private saveFile(blob: any, filename: string): void {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 0)
    }
  }

  getMapPrintRequestBody(): MapPrint {
    const extent = this.mapService.getMap().getView().calculateExtent();

    return {
      type: this.printType,
      areaUnit: this.areaUnit,
      code: this.code,
      date: (new Date).toISOString(),
      orientation: this.orientation,
      sign: this.sign,
      scale: this.scale.toString(),
      resolution: this.printResolution,
      format: this.format,
      bbox: {
        xMin: extent[1].toFixed().toString(),
        yMin: extent[0].toFixed().toString(),
        xMax: extent[3].toFixed().toString(),
        yMax: extent[2].toFixed().toString()
      }
    };
  }

  getFileName(type: PrintType, paper: PaperFormat) {
    const suffix = this.sign ? '.asice' : '.pdf';

    switch (type) {
      case PrintType.CUSUMMARY:
        return `Trykk_${paper}_ky_${this.code}${moment().format('YYYYMMDD_HHmmss')}${suffix}`;
      case PrintType.BASIC:
      default:
        return `Trykk_${paper}_yld_${moment().format('YYYYMMDD_HHmmss')}${suffix}`;
    }
  }

  updateSwipePosition(): void {
    const swipe = (document.getElementById('swipe') as HTMLInputElement);
    const value = +swipe.value;

    const divider = document.getElementById('divider');
    const mapSize = this.mapService.getMap().getSize();
    const width = mapSize[0] * (value / 10000);
    const height = mapSize[1] - 180;

    divider.style.left = `${width}px`;
    divider.style.height = `${height}px`;
    this.mapService.getMap().render();
  }

  isSafari(): boolean {
    return this.platform.SAFARI;
  }

  setMapDpi(dpi: number | undefined) {
    let scaleLine = undefined;
    this.mapService.getMap().getControls().forEach(control => {
      if (control instanceof ScaleLine) {
        scaleLine = control;
      }
    });
    scaleLine.setDpi(dpi);
  }
}

