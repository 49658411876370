import { Injectable } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';

@Injectable()
export class MediaObserverService {
  private mobileViewportSizes = ['xs'];
  private isMobileViewport: boolean;

  constructor(private media: MediaObserver) {
    this.isMobileViewport = false;
    this.media.asObservable().subscribe((mediaChanges: MediaChange[]) => {
      const change = mediaChanges.shift();
      if (change) {
        this.isMobileViewport = this.mobileViewportSizes.includes(change.mqAlias);
      }
    });
  }

  public isMobile(): boolean {
    return this.isMobileViewport;
  }
}
