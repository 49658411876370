<div class="d-flex modal-header justify-content-end">
    <a href="#" (click)="activeModal.dismiss()">
        <fa-icon [icon]="['fas', 'times']" class="icon mr-2"></fa-icon>
    </a>
</div>
<div class="modal-body">
    <span class="d-flex justify-content-center text-center">{{'map.historyForm.title'| translate}}</span>
    <div class="row my-2 py-2">
        <div class="col-6">
            <div class="form-group hidden">
                <div class="input-group">
                    <input name="datepickerL"
                           class="d-none"
                           ngbDatepicker
                           #datepickerL="ngbDatepicker"
                           [autoClose]="'outside'"
                           (dateSelect)="formLeftDate=$event;datepickerL.close()"
                           [displayMonths]="1">
                </div>
            </div>
            <div class="form-group">
                <label class="form-label w-100 text-center" translate="map.historical.dpLeft"></label>
                <div class="input-group">
                    <input #dpLeftDate
                           class="form-control"
                           name="dpLeftDate"
                           [value]="formatter.format(formLeftDate)"
                           (input)="formLeftDate = validateInput(formLeftDate, dpLeftDate.value)">
                    <div class="input-group-append">
                        <button class="btn btn-primary-blue no-radius p-2" (click)="datepickerL.toggle()" type="button">
                            <fa-icon class="d-flex align-self-center text-white mr-1"
                                     [icon]="['far', 'calendar']"></fa-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="form-group hidden">
                <div class="input-group">
                    <input name="datepickerR"
                           class="d-none"
                           ngbDatepicker
                           #datepickerR="ngbDatepicker"
                           [autoClose]="'outside'"
                           (dateSelect)="formRightDate=$event;datepickerR.close()"
                           [displayMonths]="1">
                </div>
            </div>
            <div class="form-group">
                <label class="form-label w-100 text-center" translate="map.historical.dpRight"></label>
                <div class="input-group">
                    <input #dpRightDate
                           class="form-control"
                           name="dpLeftDate"
                           [value]="formatter.format(formRightDate)"
                           (input)="formLeftDate = validateInput(formRightDate, dpRightDate.value)">
                    <div class="input-group-append">
                        <button class="btn btn-primary-blue no-radius p-2" (click)="datepickerR.toggle()" type="button">
                            <fa-icon class="d-flex align-self-center text-white mr-1"
                                     [icon]="['far', 'calendar']"></fa-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<div class="modal-footer">
    <div class="d-flex flex-column flex-md-row w-100">
        <div class="d-flex w-100">
            <button type="button" class="btn btn-secondary-outlined w-100 mx-2" (click)="activeModal.dismiss()">
                <span translate="map.historyForm.cancel"></span>
            </button>
        </div>
        <div class="d-flex w-100">
            <button type="button" class="btn btn-primary-blue w-100 mx-2"
                    (click)="apply()"><span
                    translate="map.historyForm.apply"></span>
            </button>
        </div>
    </div>
</div>


<ng-template #popHistoricalMode>
    <div class="popover history-tooltip-wrapper">

    </div>
</ng-template>
