<div class="card kolvik-card" *ngIf="kolvik && !isMobile()">
  <div class="kolvik-card-content">
    <div class="d-flex flex-row justify-content-between">
      <div class="d-flex align-self-center">
          <a href="#" class="back-button" (click)="back()" *ngIf="showBackButton">
              <fa-icon [icon]="['fas', 'chevron-left']" class="mr-2"></fa-icon>
          </a>
        <h5>{{ 'land_parcel.details.title' | translate }}</h5>
      </div>
      <div class="col-6 text-right p-0">
        <a href="#" (click)="clearSelection()">
          <fa-icon [icon]="['fas', 'times']" class="icon mr-2"></fa-icon>
        </a>
      </div>
    </div>

    <div class="d-flex flex-column data-box">
      <div class="row  pb-1">
        <div class="col-4">{{ 'land_parcel.details.type' | translate }}</div>
        <div class="col-4">{{kolvik?.type.name}}</div>
        <div class="col-4"></div>
      </div>
      <div class="row pb-1">
        <div class="col-4">{{ 'land_parcel.details.area' | translate }}</div>
        <div class="col-4">{{getAreaString(kolvik.area|areaUnit:areaUnit)}} <span [innerHTML]="getAreUnitText(areaUnit)" ></span></div>
        <div class="col-4"></div>
      </div>
      <div class="row pb-1">
        <div class="col-4">{{ 'land_parcel.details.version_date' | translate }}</div>
        <div class="col-4">{{kolvik.validFrom | date:'dd.MM.yyyy' }}</div>
        <div class="col-4"><a href="#" (click)="showHistory()">{{ 'land_parcel.history' | translate }}</a></div>
      </div>
    </div>
    <div class="p-1">
      <a *ngIf="isM2()" (click)="setUnit()" [routerLink]="">{{ 'land_parcel.show_in_ha' | translate }}</a>
      <a *ngIf="isHa()" (click)="setUnit()" [routerLink]="">{{ 'land_parcel.show_in_m2' | translate }}</a>
    </div>

    <div class="d-flex flex-row mt-2" *ngIf="etakObjects?.length">
      <div class="col-12 p-0">
        <div class="d-flex flex-row">
          <h5>{{ 'land_parcel.details.etak.title' | translate }}</h5>
        </div>
        <div class="d-flex flex-row m-0 p-0 etak-objects-scrollable">
          <div class="col-12 m-0 p-0">
            <ngb-accordion [closeOthers]="true" #accordion>
              <ngb-panel [cardClass]="'data-box etak-card'"
                         *ngFor="let etakObj of etakObjects, let i=index">
                <ng-template ngbPanelTitle let-opened="opened">
                  <div class="d-flex flex-row justify-content-between" (click)="showFeature(etakObj)">
                                    <span class="d-flex etak-obj-label">
                                        {{ etakObj.type ? etakObj.type?.name : etakObj.code?.name }} {{etakObj.area === 0 ? '~' : '-' }} {{getAreaString(etakObj.area|areaUnit:areaUnit)}}
                                      <span class="ml-1" [innerHTML]="getAreUnitText(areaUnit)"></span>
                                    </span>
                    <span class="d-flex align-self-center">
                      <fa-icon *ngIf="!accordion.panels._results[i].isOpen" [icon]="['fas', 'chevron-down']"
                               class="icon mr-2 text-primary"></fa-icon>
                      <fa-icon *ngIf="accordion.panels._results[i].isOpen" [icon]="['fas', 'chevron-up']"
                               class="icon mr-2 text-primary"></fa-icon>
                    </span>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent class="kolvik-card-content">
                  <div class="d-flex flex-row pb-1">
                    <div class="col-4 p-0">ETAK id</div>
                    <div class="col-8 p-0 ">
                      <a [href]="getEtakRefURl(etakObj.etakId)" target="_blank">{{etakObj.etakId}}</a>
                    </div>
                  </div>
                  <div class="d-flex flex-row pb-1">
                    <div class="col-4 p-0">Kood</div>
                    <div class="col-8 p-0">{{etakObj.code.name}}</div>
                  </div>
                  <div class="d-flex flex-row pb-1">
                    <div class="col-4 p-0">Tüüp</div>
                    <div class="col-8 p-0">{{etakObj.type.name}}</div>
                  </div>
                  <div class="d-flex flex-row pb-1">
                    <div class="col-4 p-0">Pindala</div>
                    <div class="col-8 p-0">{{getAreaString(etakObj.area|areaUnit:areaUnit)}} <span [innerHTML]="getAreUnitText(areaUnit)" ></span></div>
                  </div>
                  <div class="d-flex flex-row pb-1">
                    <div class="col-4 p-0">Versiooni kpv</div>
                    <div class="col-8 p-0">{{etakObj.validFrom | date:'dd.MM.yyyy' }}</div>
                  </div>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
          </div>
        </div>
        <hr>
        <div class="d-flex p-1">
          <fa-icon [icon]="['fas', 'download']" class="icon mr-1 text-primary"></fa-icon>
          <a href="#" (click)="download()">{{ 'land_parcel.download' | translate }}</a>
        </div>

        <div class="d-flex flex-row mt-5" style="justify-content:center;" *ngIf="collectionSize / 10 > 1">
          <ngb-pagination [(page)]="currentPage"
                          [collectionSize]="collectionSize"
                          [pageSize]="10"
                          [maxSize]="5" [boundaryLinks]="true"
                          size="sm" (pageChange)="pageChangeEvent($event)">
          </ngb-pagination>
        </div>
      </div>
        </div>
    </div>
</div>

<div style="position: fixed; top: auto; right: auto; bottom: 0; left: 0; width: 100%; z-index: 11;" *ngIf="isMobile()">
    <app-swipe-list *ngIf="kolvik && isMobile()"
                    [data]="kolvik"
                    [maxHeight]="60"
                    [snapPoints]="[55]"
                    [disableDetails]="!etakObjects || !etakObjects.length"
                    [itemListTemplateRef]="swipeListTemplateRef"
                    [itemDetailsTemplateRef]="swipeItemTemplateRef">
    </app-swipe-list>
    <ng-template #swipeListTemplateRef let-item="item">
      <div class="px-3 py-2" style="width:100%">
          <div class="d-flex flex-row">
            <div class="col-6 p-0 d-flex">
                <a href="#" class="back-button" (click)="back()" *ngIf="showBackButton">
                    <fa-icon [icon]="['fas', 'chevron-left']" class="mr-2"></fa-icon>
                </a>
              <h5>{{ 'land_parcel.details.title' | translate }}</h5>
            </div>
            <div class="col-6 text-right p-0">
              <a href="#" (click)="clearSelection()">
                <fa-icon [icon]="['fas', 'times']" class="icon mr-2"></fa-icon>
              </a>
            </div>
          </div>
          <div class="d-flex flex-column data-box">
            <div class="row  pb-1">
              <div class="col-4">{{ 'land_parcel.details.type' | translate }}</div>
              <div class="col-4">{{kolvik?.type.name}}</div>
              <div class="col-4"></div>
            </div>
            <div class="row pb-1">
              <div class="col-4">{{ 'land_parcel.details.area' | translate }}</div>
              <div class="col-4">{{getAreaString(kolvik.area)}} m<sup>2</sup></div>
              <div class="col-4"></div>
            </div>
            <div class="row pb-1">
              <div class="col-4">{{ 'land_parcel.details.version_date' | translate }}</div>
              <div class="col-4">{{kolvik.validFrom | date:'dd.MM.yyyy' }}</div>
              <div class="col-4"><a href="#" (click)="showHistory()">{{ 'land_parcel.history' | translate }}</a></div>
            </div>
          </div>
      </div>
    </ng-template>
    <ng-template #swipeItemTemplateRef let-item="item">
      <div class="px-3 py-2" style="width:100%">
        <div class="d-flex flex-row">
          <div class="col-6 p-0  d-flex">
              <a href="#" class="back-button" (click)="back()" *ngIf="showBackButton">
                  <fa-icon [icon]="['fas', 'chevron-left']" class="mr-2"></fa-icon>
              </a>
            <h5>{{ 'land_parcel.details.title' | translate }}</h5>
          </div>
          <div class="col-6 text-right p-0">
            <a href="#" (click)="clearSelection()">
              <fa-icon [icon]="['fas', 'times']" class="icon mr-2"></fa-icon>
            </a>
          </div>
        </div>
        <div class="d-flex flex-column data-box">
          <div class="row  pb-1">
            <div class="col-4">{{ 'land_parcel.details.type' | translate }}</div>
            <div class="col-4">{{kolvik?.type.name}}</div>
            <div class="col-4"></div>
          </div>
          <div class="row pb-1">
            <div class="col-4">{{ 'land_parcel.details.area' | translate }}</div>
            <div class="col-4">{{getAreaString(kolvik.area|areaUnit:areaUnit)}} <span [innerHTML]="getAreUnitText(areaUnit)" ></span></div>
            <div class="col-4"></div>
          </div>
          <div class="row pb-1">
            <div class="col-4">{{ 'land_parcel.details.version_date' | translate }}</div>
            <div class="col-4">{{kolvik.validFrom | date:'dd.MM.yyyy' }}</div>
            <div class="col-4"><a href="#" (click)="showHistory()">{{ 'land_parcel.history' | translate }}</a></div>
          </div>
        </div>
        <div class="p-1">
          <a *ngIf="isM2()" (click)="setUnit()" [routerLink]="">{{ 'land_parcel.show_in_ha' | translate }}</a>
          <a *ngIf="isHa()" (click)="setUnit()" [routerLink]="">{{ 'land_parcel.show_in_m2' | translate }}</a>
        </div>
        <div class="d-flex flex-row mt-4" *ngIf="etakObjects?.length">
          <div class="col-12 p-0">
            <div class="d-flex flex-row">
              <h5>{{ 'land_parcel.details.etak.title' | translate }}</h5>
            </div>
            <div class="d-flex flex-row m-0 p-0">
              <div class="col-12 m-0 p-0">
                <ngb-accordion [closeOthers]="true" #maccordion>
                  <ngb-panel [cardClass]="'data-box etak-card'"
                             *ngFor="let etakObj of etakObjects, let i=index">
                    <ng-template ngbPanelTitle let-opened="opened">
                      <div class="d-flex flex-row justify-content-between" (click)="showFeature(etakObj)">
                                    <span class="d-flex etak-obj-label">
                                        {{ etakObj.type.name }} {{etakObj.area === 0 ? '~' : '-' }} {{getAreaString(etakObj.area|areaUnit:areaUnit)}}
                                      <span class="ml-1" [innerHTML]="getAreUnitText(areaUnit)"></span>
                                    </span>
                        <span class="d-flex align-self-center">
                      <fa-icon *ngIf="!maccordion.panels._results[i].isOpen" [icon]="['fas', 'chevron-down']"
                               class="icon mr-2 text-primary"></fa-icon>
                      <fa-icon *ngIf="maccordion.panels._results[i].isOpen" [icon]="['fas', 'chevron-up']"
                               class="icon mr-2 text-primary"></fa-icon>
                    </span>
                      </div>
                    </ng-template>
                    <ng-template ngbPanelContent class="kolvik-card-content">
                      <div class="d-flex flex-row pb-1">
                        <div class="col-4 p-0">ETAK id</div>
                        <div class="col-8 p-0 ">
                          <a [href]="getEtakRefURl(etakObj.etakId)" target="_blank">{{etakObj.etakId}}</a>
                        </div>
                      </div>
                      <div class="d-flex flex-row pb-1">
                        <div class="col-4 p-0">Kood</div>
                        <div class="col-8 p-0">{{etakObj.code.name}}</div>
                      </div>
                      <div class="d-flex flex-row pb-1">
                        <div class="col-4 p-0">Tüüp</div>
                        <div class="col-8 p-0">{{etakObj.type.name}}</div>
                      </div>
                      <div class="d-flex flex-row pb-1">
                        <div class="col-4 p-0">Pindala</div>
                        <div class="col-8 p-0">{{getAreaString(etakObj.area|areaUnit:areaUnit)}} <span [innerHTML]="getAreUnitText(areaUnit)" ></span></div>
                      </div>
                      <div class="d-flex flex-row pb-1">
                        <div class="col-4 p-0">Versiooni kpv</div>
                        <div class="col-8 p-0">{{etakObj.validFrom | date:'dd.MM.yyyy' }}</div>
                      </div>
                    </ng-template>
                  </ngb-panel>
                </ngb-accordion>
                        </div>
                    </div>
                    <div class="d-flex flex-row mt-5" style="justify-content:center;" *ngIf="collectionSize / 10 > 1">
                      <ngb-pagination [(page)]="currentPage"
                                      [collectionSize]="collectionSize"
                                      [pageSize]="10"
                                      [maxSize]="5" [boundaryLinks]="true"
                                      size="sm" (pageChange)="pageChangeEvent($event)">
                      </ngb-pagination>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</div>
