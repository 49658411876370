import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {InAdsService} from '../../api/inads/service/in-ads.service';
import {InAdsConfig} from './inads-config.model';
import {InAdsAddress} from '../../api/inads/model/in-ads-address.model';
import {catchError, debounceTime, distinctUntilChanged, map, switchMap, tap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {JhiEventManager} from "ng-jhipster";


@Component({
  selector: 'inads-search',
  templateUrl: './inads.component.html',
  styleUrls: ['./inads.component.scss']
})
export class InadsComponent implements OnInit {

  @Input()
  inAdsConfig: InAdsConfig;
  @Input()
  showPlaceHolder = true;

  address: InAdsAddress;
  searching = false;
  searchFailed = false;
  @Output() selectedItem: EventEmitter<any> = new EventEmitter();


  constructor(private service: InAdsService, private eventManager: JhiEventManager) {
    this.inAdsConfig = this.getDefaultConfig();
  }


  ngOnInit(): void {
    this.eventManager.subscribe('CLEAR_SELECTION', () => {
      this.address = undefined;
    })
  }

  formatter = (result: InAdsAddress) => result.aadresstekst + ' [' + result.tunnus + ']';

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term =>
        this.service.getCadasters(this.inAdsConfig.callback, term, null, this.inAdsConfig.results, this.inAdsConfig.appartment, this.inAdsConfig.unik, this.inAdsConfig.features, this.inAdsConfig.ihist).pipe(
          map(a => a.addresses),
          tap(() => this.searchFailed = false),
          catchError(error => {
            this.searchFailed = true;
            return of([]);
          }))
      ),
      tap(() => this.searching = false),
    )


  select(adsoid: string): void {
    if (adsoid) {
      this.service.getCadasters(this.inAdsConfig.callback, null, adsoid, null, null, this.inAdsConfig.unik, null, null).subscribe(resp =>
        this.address = resp.addresses.pop()
      )
    }
  }

  getDefaultConfig(): InAdsConfig {
    const config: InAdsConfig = {};
    config.results = 5;
    config.appartment = 0;
    config.unik = 0;
    config.features = ['KATASTRIYKSUS'];
    return config
  }

  onItemSelect(event: any) {
    this.selectedItem.emit(event);
  }
}
