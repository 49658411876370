/**
 * landuse
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { CadastreUnit } from '../model/models';
import { ExportFormat } from '../model/models';
import { ExportResult } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class CadastreUnitService {

    protected basePath = 'https://$';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }



    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object") {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * cadastre-unit
     * @param date Date
     * @param format format
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadCadastreUnits(date: string, format: ExportFormat, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ExportResult>;
    public downloadCadastreUnits(date: string, format: ExportFormat, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ExportResult>>;
    public downloadCadastreUnits(date: string, format: ExportFormat, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ExportResult>>;
    public downloadCadastreUnits(date: string, format: ExportFormat, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (date === null || date === undefined) {
            throw new Error('Required parameter date was null or undefined when calling downloadCadastreUnits.');
        }
        if (format === null || format === undefined) {
            throw new Error('Required parameter format was null or undefined when calling downloadCadastreUnits.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (date !== undefined && date !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>date, 'date');
        }
        if (format !== undefined && format !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>format, 'format');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ExportResult>(`${this.configuration.basePath}/cadastre-unit/download-all`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * cadastre-unit
     * @param date Enables to query historical data. If filled, then system returns land parcel data, as it was on that date
     * @param format format
     * @param code Cadastre unit code(s).
     * @param x the X coordinates in L-EST format.
     * @param y the Y coordinates in L-EST format.
     * @param bbox If filled, then system first finds the cadastre units under the polygon and then returns land parcels and land parcel summaries. Must be in \&quot;Well-known text\&quot; format
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportCadastreUnits(date: string, format: ExportFormat, code?: Array<string>, x?: number, y?: number, bbox?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Blob>;
    public exportCadastreUnits(date: string, format: ExportFormat, code?: Array<string>, x?: number, y?: number, bbox?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Blob>>;
    public exportCadastreUnits(date: string, format: ExportFormat, code?: Array<string>, x?: number, y?: number, bbox?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Blob>>;
    public exportCadastreUnits(date: string, format: ExportFormat, code?: Array<string>, x?: number, y?: number, bbox?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (date === null || date === undefined) {
            throw new Error('Required parameter date was null or undefined when calling exportCadastreUnits.');
        }
        if (format === null || format === undefined) {
            throw new Error('Required parameter format was null or undefined when calling exportCadastreUnits.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (date !== undefined && date !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>date, 'date');
        }
        if (code) {
            code.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'code');
            })
        }
        if (x !== undefined && x !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>x, 'x');
        }
        if (y !== undefined && y !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>y, 'y');
        }
        if (bbox !== undefined && bbox !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>bbox, 'bbox');
        }
        if (format !== undefined && format !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>format, 'format');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get(`${this.configuration.basePath}/cadastre-unit/export`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find cadastre units and get their land parcels and land parcel summary
     * @param date Enables to query historical data. If filled, then system returns land parcel data, as it was on that date
     * @param code Cadastre unit code(s).
     * @param x the X coordinates in L-EST format.
     * @param y the Y coordinates in L-EST format.
     * @param bbox If filled, then system first finds the cadastre units under the polygon and then returns land parcels and land parcel summaries. Must be in \&quot;Well-known text\&quot; format
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findCadastreUnits(date: string, code?: Array<string>, x?: number, y?: number, bbox?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<CadastreUnit>>;
    public findCadastreUnits(date: string, code?: Array<string>, x?: number, y?: number, bbox?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<CadastreUnit>>>;
    public findCadastreUnits(date: string, code?: Array<string>, x?: number, y?: number, bbox?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<CadastreUnit>>>;
    public findCadastreUnits(date: string, code?: Array<string>, x?: number, y?: number, bbox?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (date === null || date === undefined) {
            throw new Error('Required parameter date was null or undefined when calling findCadastreUnits.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (date !== undefined && date !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>date, 'date');
        }
        if (code) {
            code.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'code');
            })
        }
        if (x !== undefined && x !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>x, 'x');
        }
        if (y !== undefined && y !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>y, 'y');
        }
        if (bbox !== undefined && bbox !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>bbox, 'bbox');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<CadastreUnit>>(`${this.configuration.basePath}/cadastre-unit/find`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
