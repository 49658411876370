import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoaderService {
  private loader = new BehaviorSubject<boolean>(true);

  constructor() {}

  getLoader(): Observable<boolean> {
    return this.loader.asObservable();
  }

  hideLoader(): void {
    setTimeout(() => {
      this.loader.next(false);
    }, 10);
  }

  showLoader(): void {
    setTimeout(() => {
      this.loader.next(true);
    }, 10);
  }
}
