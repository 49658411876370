import {Location} from '@angular/common';
import {Injectable} from '@angular/core';


import {Logout} from './logout.model';
import {AuthServerProvider} from '../auth/auth-session.service';
import {StateStorageService} from '../auth/state-storage.service';

@Injectable({providedIn: 'root'})
export class LoginService {
  constructor(private location: Location, private authServerProvider: AuthServerProvider, private stateStorageService: StateStorageService) {
  }

  login(): void {
    this.stateStorageService.clearSelectedRole();

    // If you have configured multiple OIDC providers, then, you can update this URL to /login.
    // It will show a Spring Security generated login page with links to configured OIDC providers.
    location.href = `${location.origin}${this.location.prepareExternalUrl('oauth2/authorization/oidc')}`;
  }

  logout(): void {
    this.stateStorageService.clearSelectedRole();

    this.authServerProvider.logout().subscribe((logout: Logout) => {
      const redirectUri = `${location.origin}${this.location.prepareExternalUrl('/')}`;
      const logoutUrl = logout.logoutUrl + '?post_logout_redirect_uri=' + redirectUri + '&id_token_hint=' + logout.idToken;
      window.location.href = logoutUrl;
    });
  }
}
