import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NgbDateStruct, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {JhiEventManager} from 'ng-jhipster';
import {Subscription} from 'rxjs';
import {CadastreUnit} from '../../api/land-parcel-api/model/cadastre-unit.model';
import {LandParcelSummary} from '../../api/land-parcel-api/model/land-parcel-summary.model';
import {LandParcel} from '../../api/land-parcel-api/model/land-parcel.model';
import {PrintType} from '../../api/land-parcel-api/model/print-type.model';
import {CadastreUnitService} from '../../api/land-parcel-api/service/cadastre-unit.service';
import {LandParcelService} from '../../api/land-parcel-api/service/land-parcel.service';
import {DownloadModalComponent} from '../download-modal-component/download-modal.component';
import {InfoRequestType, MapMode} from '../map/map.component';
import {
  MAP_CLICK_EVENT,
  MAP_DESKTOP_PADDING,
  MAP_HISTORY_CHANGE_EVENT,
  SHOW_LAND_PARCEL_DETAILS,
  SHOW_LAST_CADASTRE_UNIT
} from '../map/map.model';
import {MapService} from '../map/map.service';
import {AreaUnit, GlobalStateService, MapClickedSide} from '../service/global-state.service';
import {MediaObserverService} from '../service/media-observer.service';

export enum CadastreUnitViewMode {
  DETAILS = 'DETAILS',
  SUMMARY = 'SUMMARY',

}

export const CUSUMMARY_PRINT = 'CUSUMMARY_PRINT';

@Component({
  selector: 'app-cadastre-unit',
  templateUrl: './cadastre-unit.component.html',
  styleUrls: ['cadastre-unit.component.scss'],
})
export class CadastreUnitComponent implements OnInit, OnDestroy, AfterViewInit {

  selectedDate: NgbDateStruct;
  activeLandParcel: LandParcel;

  @Input()
  set historyDate(date: NgbDateStruct) {
    this.selectedDate = date;
  }

  cadastreUnit: CadastreUnit;

  currentPage = 1;
  collectionSize = 1;

  mapClickListener: Subscription;
  historyChangeListener: Subscription;
  showLastCadastreunitListener: Subscription;

  areaUnit: AreaUnit;

  mode = CadastreUnitViewMode.SUMMARY;

  constructor(
    private mapService: MapService,
    private eventManager: JhiEventManager,
    private cadastreUnitService: CadastreUnitService,
    private landParcelService: LandParcelService,
    private mediaObserverService: MediaObserverService,
    private globalStateService: GlobalStateService,
    private modalService: NgbModal
  ) {
  }

  ngAfterViewInit(): void {
    this.subscribeToMap();
  }

  ngOnInit(): void {

    this.globalStateService.getAreaUnit().subscribe(value => {
      this.areaUnit = value;
    });
  }


  private subscribeToMap(): void {
    this.mapClickListener = this.eventManager.subscribe(MAP_CLICK_EVENT, event => {
      if (event.content.requestType === InfoRequestType.CADASTRE_UNIT) {
        this.clearSelection();
        const dateString = this.getSelectedDate();
        const filter = {
          x: event.content.coordinate[0],
          y: event.content.coordinate[1],
          date: dateString || event.content.date
        };
        if (dateString) {
          filter.date = dateString;
        }

        if (event.content.cadastreCode) {
          this.globalStateService.setLastCadastreUnitCode(event.content.cadastreCode);
        }

        this.cadastreUnitService.findCadastreUnits(filter.date, event.content.cadastreCode ? [event.content.cadastreCode] : null, filter.x, filter.y, 'body').subscribe(response => {
          if (response) {
            const options = {
              padding: this.mediaObserverService.isMobile() ? [150, 5, 150, 5] : MAP_DESKTOP_PADDING,
              duration: 500
            };

            this.cadastreUnit = response[0];
            this.globalStateService.setLastCadastreUnit(this.cadastreUnit);
            this.mapService.addFeatures(this.cadastreUnit.geometry, 'SELECT_LAYER', options);
          }
        });
      } else {
        this.cadastreUnit = undefined;
      }
    });


    this.historyChangeListener = this.eventManager.subscribe(MAP_HISTORY_CHANGE_EVENT, () => {
      this.clearSelection();
    });

    this.showLastCadastreunitListener = this.eventManager.subscribe(SHOW_LAST_CADASTRE_UNIT, () => {
      const options = {
        padding: this.mediaObserverService.isMobile() ? [150, 5, 150, 5] : MAP_DESKTOP_PADDING,
        duration: 500
      };
      this.cadastreUnit = this.globalStateService.getLastCadastreUnit();
      this.globalStateService.setLastCadastreUnit(this.cadastreUnit);
      this.mapService.addFeatures(this.cadastreUnit.geometry, 'SELECT_LAYER', options);
    });
  }

  clearSelection(): void {
    this.cadastreUnit = undefined;
    this.mapService.clearLayer('SELECT_LAYER');
    this.mapService.clearLayer('ETAK_LAYER');
    this.mapService.moveMapLayerSelectButton({bottom: 0}, '');

    this.eventManager.broadcast('CLEAR_SELECTION');
  }

  showFeature(landParcel: LandParcel): void {
    this.mapService.clearLayer('ETAK_LAYER');
    if (this.activeLandParcel !== landParcel) {
      this.activeLandParcel = landParcel;
      this.mapService.addFeatures(landParcel.geometry, 'ETAK_LAYER');
    } else {
      this.activeLandParcel = null;
    }
  }

  getAreaString(area: any): string {
    if (!area) {
      return '0';
    }
    return area.toFixed(this.areaUnit === AreaUnit.HA ? 2 : 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }

  getAreaUnitText(value: AreaUnit): string {
    if (value === AreaUnit.M2) {
      return ' m<sup>2</sup>';
    }
    if (value === AreaUnit.HA) {
      return ' ha';
    }
    return '';
  }

  isHa(): boolean {
    return this.areaUnit === AreaUnit.HA;
  }

  isM2(): boolean {
    return this.areaUnit === AreaUnit.M2;
  }

  isMobile(): boolean {
    return this.mediaObserverService.isMobile();
  }

  ngOnDestroy(): void {
    this.mapClickListener.unsubscribe();
    this.historyChangeListener.unsubscribe();
    this.showLastCadastreunitListener.unsubscribe();
  }

  setUnit(): void {
    if (this.areaUnit === AreaUnit.M2) {
      this.globalStateService.setAreaUnit(AreaUnit.HA);
    } else {
      this.globalStateService.setAreaUnit(AreaUnit.M2);
    }
  }

  getAddress(): string {
    const address: string[] = [];
    if (this.cadastreUnit?.address?.shortAddress) {
      address.push(this.cadastreUnit?.address?.shortAddress);
    }
    if (this.cadastreUnit?.address?.adsLevel3) {
      address.push(this.cadastreUnit?.address?.adsLevel3);
    }
    if (this.cadastreUnit?.address?.adsLevel2) {
      address.push(this.cadastreUnit?.address?.adsLevel2);
    }
    if (this.cadastreUnit?.address?.adsLevel1) {
      address.push(this.cadastreUnit?.address?.adsLevel1);
    }
    return address.join(', ');
  }

  showHistory(): void {
    this.globalStateService.setMapMode(MapMode.HISTORICAL);
  }

  showLandParcelDetails(id: number): void {
    this.landParcelService.getLandParcel(id).subscribe(resp => {
      if (resp.id) {
        this.eventManager.broadcast({name: SHOW_LAND_PARCEL_DETAILS, content: {resp}});
        this.cadastreUnit = undefined;
      }
    });
  }

  download(): void {
    const modalRef = this.modalService.open(DownloadModalComponent);
    modalRef.componentInstance.type = InfoRequestType.CADASTRE_UNIT;
    modalRef.componentInstance.code = this.cadastreUnit.code;
    modalRef.componentInstance.date = this.globalStateService.getHistoricalStateValue().side === MapClickedSide.LEFT ?
      this.globalStateService.getHistoricalStateValue().leftDate : this.globalStateService.getHistoricalStateValue().rightDate;
  }

  getCadastreUnitSummary(): LandParcelSummary[] {
    return this.cadastreUnit.landParcelSummary ? this.cadastreUnit.landParcelSummary : [];
  }

  print(): void {
    this.eventManager.broadcast({
      name: CUSUMMARY_PRINT, content: {
        code: this.cadastreUnit.code,
        date: this.getSelectedDate(),
        printType: PrintType.CUSUMMARY,
        areaUnit: this.areaUnit
      }
    });
  }

  getSelectedDate(): string {
    return this.globalStateService.getHistoricalStateValue().side === MapClickedSide.LEFT ?
      this.globalStateService.getHistoricalStateValue().leftDate : this.globalStateService.getHistoricalStateValue().rightDate;
  }

  switchToDetails(): void {
    this.mode = CadastreUnitViewMode.DETAILS;
  }

  switchToSummary(): void {
    this.mapService.clearLayer('ETAK_LAYER');
    this.mode = CadastreUnitViewMode.SUMMARY;
  }

  isSummaryMode(): boolean {
    return this.mode === CadastreUnitViewMode.SUMMARY;
  }
}

