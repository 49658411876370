export default {
  "kaart.pohi_vr2": "Põhikaart",
  "kaart.pohi_vv": "Värviline põhikaart (reljeefvarjutusega)",
  "kaart.pohi_mvr2": "Mustvalge põhikaart",
  "kaart.hybrid": "Hübriidkaart",
  "kaart.kaart_ht": "Hallkaart",
  "kaart.kaart24l": "Kaart (valgete teedega)",
  "kaart.eestifoto": "Ortofoto",
  "land_parcel.details.title":"Land Parcel",
  "land_parcel.details.type":"Type",
  "land_parcel.details.area":"Area",
  "land_parcel.details.version_date":"Version date",
  "land_parcel.details.etak.title":"ETAK objects"
};
