<div class="card kolvik-card" *ngIf="cadastreUnit && !isMobile()">
  <div class="kolvik-card-content">
    <div class="d-flex flex-row justify-content-between">
      <div class="d-flex">
        <h5>{{ 'cadastre_unit.details.title' | translate }}</h5>
      </div>
      <div class="col-6 text-right p-0">
        <a href="#" (click)="clearSelection()">
          <fa-icon [icon]="['fas', 'times']" class="icon mr-2"></fa-icon>
        </a>
      </div>
    </div>

    <div class="d-flex flex-column data-box">
      <div class="row pb-1">
        <div class="col-4">Tunnus</div>
        <div class="col-4">{{cadastreUnit.code}}</div>
        <div class="col-4"></div>
      </div>
      <div class="row pb-1">
        <div class="col-4">Aadress</div>
        <div class="col-8">{{getAddress()}}</div>
      </div>
      <div class="row pb-1">
        <div class="col-4">{{ 'cadastre_unit.details.area' | translate }}</div>
        <div class="col-4">{{getAreaString(cadastreUnit.area|areaUnit:areaUnit)}} <span
          [innerHTML]="getAreaUnitText(areaUnit)"></span></div>
        <div class="col-4"></div>
      </div>
      <div class="row pb-1">
        <div class="col-4">{{ 'cadastre_unit.details.version_date' | translate }}</div>
        <div class="col-4">{{cadastreUnit.validFrom | date:'dd.MM.yyyy' }}</div>
        <div class="col-4"><a href="#" (click)="showHistory()">{{ 'land_parcel.history' | translate }}</a></div>
      </div>
    </div>
    <div class="p-1">
      <a *ngIf="isM2()" (click)="setUnit()" [routerLink]="">{{ 'land_parcel.show_in_ha' | translate }}</a>
      <a *ngIf="isHa()" (click)="setUnit()" [routerLink]="">{{ 'land_parcel.show_in_m2' | translate }}</a>
    </div>

    <ng-container [ngTemplateOutlet]="cadastreUnitDetails" *ngIf="!isSummaryMode()"></ng-container>
    <ng-container [ngTemplateOutlet]="cadastreUnitSummary" *ngIf="isSummaryMode()"></ng-container>
    <hr>
    <div class="d-flex p-1">
      <div *ngIf="!isSummaryMode()">
        <fa-icon [icon]="['fas', 'download']" class="icon mr-1 text-primary"></fa-icon>
        <a href="#" (click)="download()">{{ 'land_parcel.download' | translate }}</a>
      </div>
      <div *ngIf="isSummaryMode()">
        <fa-icon [icon]="['fas', 'print']" class="icon mr-1 text-primary"></fa-icon>
        <a href="#" (click)="print()">{{ 'button.print' | translate }}</a>
      </div>
    </div>
  </div>
</div>

<ng-template #cadastreUnitDetails>
  <div class="d-flex flex-row mt-2" *ngIf="cadastreUnit.landParcels?.length">
    <div class="col-12 p-0">
      <div class="d-flex flex-row">
        <h5>{{ 'cadastre_unit.details.land_parcels.title' | translate }}</h5>
      </div>
      <div class="d-flex flex-row m-0 p-0 etak-objects-scrollable">
        <div class="col-12 m-0 p-0">
          <ngb-accordion [closeOthers]="true" #accordion>
            <ngb-panel [cardClass]="'data-box etak-card'"
                       *ngFor="let landParcel of cadastreUnit.landParcels, let i=index">
              <ng-template ngbPanelTitle let-opened="opened">
                <div class="d-flex flex-row justify-content-between" (click)="showFeature(landParcel)">
                                    <span class="d-flex etak-obj-label">
                                      <span
                                        class="d-flex align-self-center land-parcel-box ml-1 mr-2 land-parcel-box-{{landParcel.type.code}}"></span>
                                      {{ landParcel.type.name }} - {{getAreaString(landParcel.area|areaUnit:areaUnit)}}
                                      <span class="ml-1" [innerHTML]="getAreaUnitText(areaUnit)"></span>
                                    </span>
                  <span class="d-flex align-self-center">
                      <fa-icon *ngIf="!accordion.panels._results[i].isOpen" [icon]="['fas', 'chevron-down']"
                               class="icon mr-2 text-primary"></fa-icon>
                      <fa-icon *ngIf="accordion.panels._results[i].isOpen" [icon]="['fas', 'chevron-up']"
                               class="icon mr-2 text-primary"></fa-icon>
                    </span>
                </div>
              </ng-template>
              <ng-template ngbPanelContent class="kolvik-card-content">
                <div class="d-flex flex-row pb-1">
                  <div class="col-4 p-0">Pindala</div>
                  <div class="col-8 p-0 ">{{getAreaString(landParcel.area|areaUnit:areaUnit)}} <span class="ml-1"
                                                                                                     [innerHTML]="getAreaUnitText(areaUnit)"></span>
                  </div>
                </div>
                <div class="d-flex flex-row pb-1">
                  <div class="col-4 p-0">Versiooni kpv</div>
                  <div class="col-8 p-0">{{landParcel.validFrom | date:'dd.MM.yyyy' }}</div>
                </div>
                <div class="d-flex pt-2">
                  <a href="#" (click)="showLandParcelDetails(landParcel.id)">Vaata täpsemaid kõlviku andmeid</a>
                </div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>
      </div>
      <div class="d-flex p-1">
        <span>
          <i>{{'cadastre_unit.details.land_parcels.details_info' | translate}}</i>
          <a href="#" (click)="switchToSummary()">{{'button.click' | translate}}</a>
        </span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #cadastreUnitSummary>
  <div class="d-flex flex-row mt-2" *ngIf="cadastreUnit.landParcelSummary?.length">
    <div class="col-12 p-0">
      <div class="d-flex flex-row">
        <h5>{{ 'cadastre_unit.details.land_parcels.summary_title' | translate }}</h5>
      </div>
      <div class="d-flex flex-row m-0 p-0 etak-objects-scrollable">
        <div class="col-12 m-0 p-0">
          <ngb-accordion [closeOthers]="true" #accordion>
            <ngb-panel [cardClass]="'data-box etak-card'"
                       *ngFor="let landParcel of getCadastreUnitSummary(), let i=index">
              <ng-template ngbPanelTitle let-opened="opened">
                <div class="d-flex flex-row justify-content-between">
                  <span class="d-flex etak-obj-label">
                    <span
                      class="d-flex align-self-center land-parcel-box ml-1 mr-2 land-parcel-box-{{landParcel.type.code}}"></span>
                    {{ landParcel.type.name }}  {{landParcel.computedArea === 0 ? '~' : '-' }}  {{getAreaString(landParcel.computedArea|areaUnit:areaUnit)}}
                    <span class="ml-1" [innerHTML]="getAreaUnitText(areaUnit)"></span>
                    </span>
                </div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>
      </div>
      <div class="d-flex p-1">
        <span>
          <i>{{'cadastre_unit.details.land_parcels.summary_info' | translate}}</i>
          <a href="#" (click)="switchToDetails()">{{'button.click' | translate}}</a>
        </span>
      </div>
     </div>
  </div>
</ng-template>

<div style="position: fixed; top: auto; right: auto; bottom: 0; left: 0; width: 100%; z-index: 11;" *ngIf="isMobile()">
  <app-swipe-list *ngIf="cadastreUnit && isMobile()"
                  [data]="cadastreUnit"
                  [maxHeight]="60"
                  [snapPoints]="[55]"
                  [itemListTemplateRef]="swipeListTemplateRef"
                  [itemDetailsTemplateRef]="swipeItemTemplateRef">
  </app-swipe-list>
  <ng-template #swipeListTemplateRef let-item="item">
    <div class="px-3 py-2" style="width:100%">
      <div class="d-flex flex-row justify-content-between">
        <div class="d-flex">
          <h5>{{ 'cadastre_unit.details.title' | translate }}</h5>
        </div>
        <div class="col-6 text-right p-0">
          <a href="#" (click)="clearSelection()">
            <fa-icon [icon]="['fas', 'times']" class="icon mr-2"></fa-icon>
          </a>
        </div>
      </div>

      <div class="d-flex flex-column data-box">
        <div class="row pb-1">
          <div class="col-4">Tunnus</div>
          <div class="col-4">{{cadastreUnit.code}}</div>
          <div class="col-4"></div>
        </div>
        <div class="row pb-1">
          <div class="col-4">Aadress</div>
          <div class="col-8">{{getAddress()}}</div>
        </div>
        <div class="row pb-1">
          <div class="col-4">{{ 'cadastre_unit.details.area' | translate }}</div>
          <div class="col-4">{{getAreaString(cadastreUnit.area|areaUnit:areaUnit)}} <span
            [innerHTML]="getAreaUnitText(areaUnit)"></span></div>
          <div class="col-4"></div>
        </div>
        <div class="row pb-1">
          <div class="col-4">{{ 'cadastre_unit.details.version_date' | translate }}</div>
          <div class="col-4">{{cadastreUnit.validFrom | date:'dd.MM.yyyy' }}</div>
          <div class="col-4"><a href="#" (click)="showHistory()">{{ 'land_parcel.history' | translate }}</a></div>
        </div>
      </div>
      <div class="p-1">
        <a *ngIf="isM2()" (click)="setUnit()" [routerLink]="">{{ 'land_parcel.show_in_ha' | translate }}</a>
        <a *ngIf="isHa()" (click)="setUnit()" [routerLink]="">{{ 'land_parcel.show_in_m2' | translate }}</a>
      </div>

    </div>
  </ng-template>
  <ng-template #swipeItemTemplateRef let-item="item">
    <div class="px-3 py-2" style="width:100%">
      <div class="d-flex flex-row justify-content-between">
        <div class="d-flex">
          <h5>{{ 'cadastre_unit.details.title' | translate }}</h5>
        </div>
        <div class="col-6 text-right p-0">
          <a href="#" (click)="clearSelection()">
            <fa-icon [icon]="['fas', 'times']" class="icon mr-2"></fa-icon>
          </a>
        </div>
      </div>

      <div class="d-flex flex-column data-box">
        <div class="row pb-1">
          <div class="col-4">Tunnus</div>
          <div class="col-4">{{cadastreUnit.code}}</div>
          <div class="col-4"></div>
        </div>
        <div class="row pb-1">
          <div class="col-4">Aadress</div>
          <div class="col-8">{{getAddress()}}</div>
        </div>
        <div class="row pb-1">
          <div class="col-4">{{ 'cadastre_unit.details.area' | translate }}</div>
          <div class="col-4">{{getAreaString(cadastreUnit.area|areaUnit:areaUnit)}} <span
            [innerHTML]="getAreaUnitText(areaUnit)"></span></div>
          <div class="col-4"></div>
        </div>
        <div class="row pb-1">
          <div class="col-4">{{ 'cadastre_unit.details.version_date' | translate }}</div>
          <div class="col-4">{{cadastreUnit.validFrom | date:'dd.MM.yyyy' }}</div>
          <div class="col-4"><a href="#" (click)="showHistory()">{{ 'land_parcel.history' | translate }}</a></div>
        </div>
      </div>
      <div class="p-1">
        <a *ngIf="isM2()" (click)="setUnit()" [routerLink]="">{{ 'land_parcel.show_in_ha' | translate }}</a>
        <a *ngIf="isHa()" (click)="setUnit()" [routerLink]="">{{ 'land_parcel.show_in_m2' | translate }}</a>
      </div>
      <ng-container [ngTemplateOutlet]="cadastreUnitDetails" *ngIf="!isSummaryMode()"></ng-container>
      <ng-container [ngTemplateOutlet]="cadastreUnitSummary" *ngIf="isSummaryMode()"></ng-container>
      <hr>
      <div class="d-flex p-1">
        <div *ngIf="!isSummaryMode()">
          <fa-icon [icon]="['fas', 'download']" class="icon mr-1 text-primary"></fa-icon>
          <a href="#" (click)="download()">{{ 'land_parcel.download' | translate }}</a>
        </div>
        <div *ngIf="isSummaryMode()">
          <fa-icon [icon]="['fas', 'print']" class="icon mr-1 text-primary"></fa-icon>
          <a href="#" (click)="print()">{{ 'button.print' | translate }}</a>
        </div>
      </div>
    </div>
  </ng-template>
</div>
