import { Injectable } from '@angular/core';
import { SessionStorageService } from 'ngx-webstorage';

const PREVIOUS_URL_KEY = 'previousUrl';
const SELECTED_ROLE_KEY = 'selectedRole';

@Injectable({ providedIn: 'root' })
export class StateStorageService {

  constructor(private $sessionStorage: SessionStorageService) {}

  storePreviousUrl(url: string): void {
    this.$sessionStorage.store(PREVIOUS_URL_KEY, url);
  }

  getPreviousUrl(): string | null | undefined {
    return this.$sessionStorage.retrieve(PREVIOUS_URL_KEY);
  }

  clearPreviousUrl(): void {
    this.$sessionStorage.clear(PREVIOUS_URL_KEY);
  }

  storeSelectedRole(role: any): void {
    this.$sessionStorage.store(SELECTED_ROLE_KEY, role);
  }

  getSelectedRole(): any | null | undefined {
    return this.$sessionStorage.retrieve(SELECTED_ROLE_KEY);
  }

  clearSelectedRole(): void {
    this.$sessionStorage.clear(SELECTED_ROLE_KEY);
  }

}
