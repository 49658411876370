import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {JhiEventManager} from 'ng-jhipster';
import {Coordinate, format} from 'ol/coordinate';
import * as extent from 'ol/extent.js';
import {Extent} from 'ol/extent';
import Layer from 'ol/layer/Layer';
import Map from 'ol/Map';
import {get as getProjection} from 'ol/proj';
import {register} from 'ol/proj/proj4';
import View from 'ol/View';
import GeoJSON from 'ol/format/GeoJSON';
import proj4 from 'proj4';
import {MAP_EXTEND_EST, MapLayer, MapLayerType, MAX_ZOOM} from './map.layers';
import {MAP_CLICK_EVENT, MAP_DESKTOP_PADDING, MAP_HISTORY_CHANGE_EVENT} from './map.model';
import Feature from 'ol/Feature';
import VectorSource from 'ol/source/Vector';
import {Attribution, ScaleLine, Zoom} from "ol/control";
import {LoaderService} from "../service/loader.service";
import {Geometry, LineString, Polygon} from "ol/geom";
import OverlayPositioning from "ol/OverlayPositioning";
import {Geolocation, Overlay} from "ol";
import GeometryType from "ol/geom/GeometryType";
import {unByKey} from "ol/Observable";
import {measuringStyle} from "./styles";
import {getArea, getLength} from "ol/sphere";
import {Draw} from "ol/interaction";
import {TranslateService} from "@ngx-translate/core";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {GlobalStateService, MapClickedSide} from "../service/global-state.service";
import {Subscription} from "rxjs";
import TileLayer from "ol/layer/Tile";
import {getRenderPixel} from "ol/render";
import {NgbDate, NgbDateParserFormatter, NgbDateStruct, NgbModal, NgbPopover} from "@ng-bootstrap/ng-bootstrap";
import TileWMS from "ol/source/TileWMS";
import * as moment from "moment";
import {HistoryModalComponent} from "../history-modal-component/history-modal.component";
import {CUSUMMARY_PRINT} from "../cadastre-unit/cadastre-unit.component";

export const legendUrl = 'https://kolvikud.kataster.ee/geoserver/Landuse/ows?version=1.1.1&service=WMS&request=GetLegendGraphic&layer=Landuse:land_parcels_current&format=image/png&LEGEND_OPTIONS=forceTitles:off;fontAntiAliasing:true'
export const historicalLayersLeft: string[] = ['land_parcels_historical_1', 'cadastre_unit_historical_1'];
export const historicalLayersRight: string[] = ['land_parcels_historical_2', 'cadastre_unit_historical_2'];
export const historicalLayers: string[] = [...historicalLayersLeft, ...historicalLayersRight];

export const LAND_PARCELS_CURRENT = 'land_parcels_current';

export enum DEVICE_POSITIONING_MODE {
  NONE = 'NONE',
  POSITIONING = 'POSITIONING',
}

export enum DrawEventType {
  DRAWSTART = 'drawstart',
  DRAWEND = 'drawend',
  DRAWABORT = 'drawabort',
}

export enum MeasuringType {
  LINE = 'LINE',
  AREA = 'AREA',
}

export enum MapMode {
  NORMAL = 'NORMAL',
  HISTORICAL = 'HISTORICAL'
}

export enum InfoRequestType {
  LAND_PARCEL = 'LAND_PARCEL',
  CADASTRE_UNIT = 'CADASTRE_UNIT'
}

export const EPSG_3301 = 'EPSG:3301';
export const EPSG_3857 = 'EPSG:3857';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],

})
export class MapComponent implements OnInit, OnDestroy, AfterViewInit {
  readonly DT_FORMAT = 'YYYY-MM-DD';

  mapLayers: MapLayer[];

  map: Map | null = null;
  view: View | null = null;
  layers: Layer[] | null = null;
  customZoom: Zoom | undefined;

  opacity: number;

  sketch: any;
  helpTooltipElement: any;
  helpTooltip: any;
  measureTooltipElement: any;
  measureTooltip: any;
  continuePolygonMsg = 'Click to continue drawing the polygon';
  continueLineMsg = 'Click to continue drawing the line';
  startMsg = 'Click to continue drawing the line';
  draw: Draw | undefined;
  measuringType: MeasuringType = MeasuringType.AREA;
  listener: any;

  activeLayer = 'SELECT_LAYER';
  viewOptions = {duration: 500, padding: MAP_DESKTOP_PADDING};

  private MAP_EXTENT: Extent = [40500, 5993000, 1064500, 7017000];
  private MAP_CENTER: Coordinate = [570181.19638, 6505859.0059];

  positioningMode = DEVICE_POSITIONING_MODE.NONE;
  geolocation!: Geolocation;
  private devicePosition!: Overlay;
  private infoMarkerOverlay!: Overlay;
  private infoMarkerCallback!: () => void;

  form: FormGroup;

  mapMode: MapMode;
  mapModeSubscription: Subscription;
  historicalLayerLeft: TileLayer;
  historicalLayerRight: TileLayer;

  leftDate: NgbDate | null;
  rightDate: NgbDate | null;

  swipeMax = 10000
  swipeContainer: any;

  private infoClickOverlay!: Overlay;

  @ViewChild('popoverMeasuring') popoverMeasuring: NgbPopover;
  @ViewChild('popoverPrint') popoverPrint: NgbPopover;

  printModalIsShown: boolean;

  constructor(
    private eventManager: JhiEventManager,
    protected loaderService: LoaderService,
    protected globalStateService: GlobalStateService,
    protected translateService: TranslateService,
    protected fb: FormBuilder,
    public formatter: NgbDateParserFormatter,
    private modalService: NgbModal
  ) {
    proj4.defs(
      'EPSG:3301',
      '+proj=lcc +lat_1=59.33333333333334 +lat_2=58 +lat_0=57.51755393055556 ' +
      '+lon_0=24 +x_0=500000 +y_0=6375000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +axis=neu +no_defs'
    );
    register(proj4);
    this.continuePolygonMsg = translateService.instant('measuringTooltip.continuePolygonMsg');
    this.continueLineMsg = translateService.instant('measuringTooltip.continueLineMsg');
    this.startMsg = translateService.instant('measuringTooltip.startMsg');

    this.leftDate = this.now();
    this.rightDate = this.now()

  }

  now(): NgbDate {
    let now: Date = new Date();
    return new NgbDate(now.getFullYear(), now.getMonth() + 1, now.getDate());
  }

  ngOnInit(): void {
    this.form = this.createForm();
    this.registerToCuSummaryPrint();
  }

  ngAfterViewInit(): void {
    this.onSwipePositionChange();
    this.swipeContainer = document.getElementById('swipeContainer') as HTMLElement;
  }

  init(layers: MapLayer[]): void {
    this.mapLayers = layers;

    proj4.defs(
      'EPSG:3301',
      '+proj=lcc +lat_1=59.33333333333334 +lat_2=58 +lat_0=57.51755393055556 ' +
      '+lon_0=24 +x_0=500000 +y_0=6375000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +axis=neu +no_defs'
    );
    register(proj4);
    const epsg3301 = getProjection('EPSG:3301');
    epsg3301.setExtent(MAP_EXTEND_EST);

    this.clearOlViewports();

    this.view = new View({
      projection: epsg3301,
      center: [570181.19638, 6505859.0059],
      zoom: 4,
      maxZoom: MAX_ZOOM
    });

    this.map = new Map({
      target: document.getElementById('map') as HTMLElement,
      layers: this.mapLayers.map(mapLayer => mapLayer.layers).reduce((l1, l2) => [...l1, ...l2]),
      view: this.view
    });


    this.customZoom = new Zoom({
      className: 'custom-ol-zoom',
      target: 'customZoom',
      zoomInTipLabel: 'Suumi sisse',
      zoomOutTipLabel: 'Suumi välja'
    })
    this.map.addControl(this.customZoom);

    this.map.addControl(new Attribution({collapsible: false}));

    this.addScaleLine();

    this.addMousePosition();

    this.registerInteractions();

    this.registerInfoMarker();

    this.registerGeoLocation();

    this.registerMapModeChange();

    this.registerInfoClick();

    this.registerMapLoaded();

  }

  createForm(): FormGroup {
    return this.fb.group({
      location: new FormControl('', []),
      x: new FormControl('', []),
      y: new FormControl('', []),
    });
  }

  addMousePosition(): void {
    this.map.on('pointermove', (e) => {
      document.getElementById('mouse-position')!.innerHTML = format(e.coordinate, 'X:{y}, Y:{x}', 2);
    });
  }

  addScaleLine(): void {
    this.map.addControl(new ScaleLine({
      units: 'metric',
      bar: true,
      steps: 2,
      text: true,
      minWidth: 140,
    }))
  }

  registerMapModeChange(): void {
    this.mapModeSubscription = this.globalStateService.getMapMode().subscribe(mode => {
      this.mapMode = mode;
      this.switchMapMode(this.mapMode)
    });
  }

  registerInteractions(): void {
    this.map.on('singleclick', (event) => {
      if (!this.popoverMeasuring?.isOpen()) {
        this.infoClickOverlay.setPosition(event.coordinate);
      }
    });
    this.map.on('precompose', (event) => {
      this.loaderService.showLoader();
    });
    this.map.on('rendercomplete', (event) => {
      this.loaderService.hideLoader();
    });
  }

  registerMapLoaded(): void {
    this.map.once('postcompose', (event) => {
      if (this.globalStateService.getLastCadastreUnitCode() && this.globalStateService.getLastCadastreUnit()) {
        this.addFeatures(this.globalStateService.getLastCadastreUnit()?.geometry, 'SELECT_LAYER', {});
        this.globalStateService.setLastCadastreUnitCode(null);
      }
    });
  }

  closeInfoOverlay(): void {
    this.infoClickOverlay.setPosition(undefined);
  }


  requestCadastreUnitInfo(event: any, coordinate?: Coordinate) {
    this.updateHistoricalState(this.getClickedSide(event), null, InfoRequestType.CADASTRE_UNIT, this.format(this.leftDate), this.format(this.rightDate))
    this.getClickedSide(event);
    this.broadcastInfoRequest(coordinate ? coordinate : this.infoClickOverlay.getPosition(), InfoRequestType.CADASTRE_UNIT);
    this.infoClickOverlay.setPosition(undefined);
  }

  requestLandParcelInfo(event: any) {
    this.updateHistoricalState(this.getClickedSide(event), null, InfoRequestType.LAND_PARCEL, this.format(this.leftDate), this.format(this.rightDate))
    this.broadcastInfoRequest(this.infoClickOverlay.getPosition(), InfoRequestType.LAND_PARCEL);
    this.infoClickOverlay.setPosition(undefined);
  }

  updateHistoricalState(side: MapClickedSide, layer: string, action: InfoRequestType, leftDate: string, rightDate: string) {
    this.globalStateService.setHistoricalState({side, layer, action, leftDate, rightDate});
  }

  broadcastInfoRequest(coordinate, requestType: InfoRequestType) {
    if (!this.map.getInteractions().getArray().includes(this.draw)) {
      this.eventManager.broadcast({
        name: MAP_CLICK_EVENT,
        content: {requestType, coordinate}
      });
    }
  }

  registerInfoMarker() {
    this.infoMarkerOverlay = new Overlay({
      element: document.getElementById('info-marker') as HTMLElement,
      autoPan: true,
      autoPanMargin: 200,
    });

    this.map.addOverlay(this.infoMarkerOverlay);
  }

  registerInfoClick() {
    this.infoClickOverlay = new Overlay({
      element: document.getElementById('clickPopup') as HTMLElement,
      autoPan: true,
      autoPanMargin: 200,
    });

    this.map.addOverlay(this.infoClickOverlay);
  }

  registerGeoLocation(): void {
    this.geolocation = new Geolocation({
      trackingOptions: {
        enableHighAccuracy: true,
      },
      tracking: false,
      projection: this.view.getProjection(),
    });

    this.geolocation.on('change', () => {
      this.trackingMode()
    })

    this.devicePosition = new Overlay({
      element: document.getElementById('device-position') as HTMLElement,
      autoPan: true,
      autoPanMargin: 200,
      positioning: OverlayPositioning.CENTER_CENTER
    });

    this.map.addOverlay(this.devicePosition);
  }

  private registerPointerMoveHandler(): void {
    this.map?.on('pointermove', (evt: any) => {
      this.pointerMoveHandler(evt);
    });
  }

  private unRegisterPointerMoveHandler(): void {
    this.map?.un('pointermove', (evt: any) => {
      this.pointerMoveHandler(evt);
    });
  }


  getBaseMapLayers(): MapLayer[] {
    return this.mapLayers?.filter(mapLayer => mapLayer.layerType === MapLayerType.BASE);
  }

  clearOlViewports(): void {
    const matches = document.getElementsByClassName('ol-viewport');
    for (let i = 0; i < matches.length; i++) {
      matches[i].remove();
    }
  }


  toggleBaseMapLayer(baseLayer: MapLayer): void {
    this.mapLayers
      .filter(mapLayer => mapLayer.layerType === MapLayerType.BASE)
      .filter(mapLayer => baseLayer.id !== mapLayer.id)
      .forEach(mapLayer => mapLayer.setVisible(false));
    baseLayer.setVisible(!baseLayer.getVisible());
  }

  toggleFeatureMapLayer(featureLayer: MapLayer): void {
    const layer = this.getFeatureLayers().find(f => f.id === featureLayer.id);
    layer.setVisible(!layer.getVisible());
  }

  isVisible(featureLayer: MapLayer): boolean {
    return this.getFeatureLayers().find(f => f.id === featureLayer.id).getVisible();
  }

  isHistorical(): boolean {
    return this.mapMode === MapMode.HISTORICAL;
  }

  public addFeatureLayer(mapLayer: MapLayer): void {
    this.mapLayers.push(mapLayer);
    this.addLayersToMap(mapLayer);
  }

  private addLayersToMap(mapLayer: MapLayer): void {
    if (mapLayer.layers.length !== 0) {
      mapLayer.layers.forEach((layer) => this.map?.addLayer(layer));
    }
    mapLayer.childMapLayers.forEach((child) => this.addLayersToMap(child));
  }

  getFeatureLayers(): MapLayer[] {
    return this.mapLayers.filter(mapLayer => mapLayer.layerType === MapLayerType.FEATURE_LAYER && mapLayer.historical === (this.mapMode === MapMode.HISTORICAL));
  }

  getOpacity(layer: MapLayer): number {
    return layer.getOpacity() * 100;
  }

  setOpacity(layer: MapLayer, opacity: any): void {
    if (opacity && opacity.newValue) {
      layer.setOpacity(opacity?.newValue / 100);
    }
  }

  getActiveLayerName(): string {
    return this.activeLayer;
  }

  getMapLayer(layerId: string): MapLayer {
    return this.mapLayers?.filter(layer => layer.id === layerId)[0];
  }

  setViewOptions(optOptions: any): void {
    const options = optOptions || {};
    this.viewOptions = {...this.viewOptions, ...options};
  }

  addFeatures(geometry: any, layer: string, options: any): void {
    this.clearFeatures(layer);
    this.activeLayer = layer;
    const geom = this.toFeature(geometry);
    (this.getMapLayer(layer)?.layers[0]?.getSource() as VectorSource)?.addFeature(geom);
    this.positionByExtent(this.getMapLayer(layer)?.layers[0]?.getSource(), options);
  }

  clearFeatures(layer: string): void {
    this.getMapLayer(layer)?.layers[0]?.getSource()?.clear();
    this.activeLayer = 'SELECT_LAYER';
  }

  public clearLayer(layerId: string): void {
    this.mapLayers
      .filter(mapLayer => layerId === mapLayer.id)
      .forEach(mapLayer => {
        mapLayer.layers.forEach(layer => {
          if (layer.getSource()) {
            layer.getSource().clear();
          }
        })
      });
  }

  getMeasuringLayer(): MapLayer {
    return this.mapLayers.filter(mapLayer => mapLayer.layerType === MapLayerType.MEASURING_LAYER)[0];
  }

  private positionByExtent(vectorSource: any, optOptions?: any): void {
    if (!vectorSource) return;
    const options = optOptions || {};
    let ext: Extent = extent.createEmpty();
    const features = vectorSource.getFeatures();
    features.forEach((feature: any) => {
      ext = extent.extend(ext, feature.getGeometry().getExtent());
    });
    this.fitByExtent(ext, options);
  }

  toFeature(geometry: any): Feature {
    return (new GeoJSON()).readFeature(Object.assign({}, JSON.parse(geometry)));
  }

  public fitByExtent(ext: Extent, optOptions?: any): void {
    const options = {...this.viewOptions, ...optOptions};
    if (this.view) {
      this.view.fit(ext, options);
    }
    if (optOptions.padding) {
      this.viewOptions.padding = optOptions.padding;
    }
  }

  public fitFeatureWithPadding(newPadding: any[]): void {
    if (newPadding) {
      this.positionByExtent(this.getMapLayer(this.activeLayer).layers[0].getSource(), {padding: newPadding});
    }
  }

  public moveMapLayerSelectButton(customStyle: any, withTransition: string): void {
    const mapLayerSelectButton = document.getElementById('layerSelect');
    const style = {...{bottom: '0'}, ...customStyle};
    style.bottom = style.bottom + 10 + 'px';
    if (mapLayerSelectButton) {
      style.transition = withTransition ? withTransition : null;
      Object.keys(style).forEach(key => {
        mapLayerSelectButton.style[key] = style[key];
      });
      if (withTransition) {
        setTimeout(() => {
          mapLayerSelectButton.style.transition = '';
        }, 500);
      }
    }
  }

  createHelpTooltip(): void {
    if (this.helpTooltipElement) {
      this.helpTooltipElement.parentNode.removeChild(this.helpTooltipElement);
    }
    this.helpTooltipElement = document.createElement('div');
    this.helpTooltipElement.className = 'ol-tooltip hidden';
    this.helpTooltip = new Overlay({
      element: this.helpTooltipElement,
      offset: [15, 0],
      positioning: OverlayPositioning.CENTER_LEFT,
    });
    this.map?.addOverlay(this.helpTooltip);
  }

  createMeasureTooltip(): void {
    if (this.measureTooltipElement) {
      this.measureTooltipElement.parentNode.removeChild(this.measureTooltipElement);
    }
    this.measureTooltipElement = document.createElement('div');
    this.measureTooltipElement.className = 'ol-tooltip ol-tooltip-measure';
    this.measureTooltip = new Overlay({
      element: this.measureTooltipElement,
      offset: [0, -15],
      positioning: OverlayPositioning.BOTTOM_CENTER,
    });
    this.map?.addOverlay(this.measureTooltip);
  }

  deleteHelpTooltip(): void {
    this.map?.removeOverlay(this.helpTooltip);
  }

  startMeasuring(type: string): void {
    this.registerPointerMoveHandler();
    this.map!.getViewport().addEventListener('mouseout', () => {
      this.helpTooltipElement.classList.add('hidden');
    });
    this.measuringType = type as MeasuringType
    this.addMeasureInteraction(this.measuringType);
  }

  endMeasuring(): void {
    this.clearLayer('MEASURING_LAYER');
    if (this.helpTooltipElement) {
      this.helpTooltipElement.classList.add('hidden');
    }

    this.map?.removeInteraction(this.draw!);
    this.deleteHelpTooltip();
    this.deleteMeasureTooltip();
    this.unRegisterPointerMoveHandler();
  }

  pointerMoveHandler(evt: any): void {
    if (evt.dragging) {
      return;
    }
    let helpMsg = this.startMsg;

    if (this.sketch) {
      const geom = this.sketch.getGeometry();
      if (geom instanceof Polygon) {
        helpMsg = this.continuePolygonMsg;
      } else if (geom instanceof LineString) {
        helpMsg = this.continueLineMsg;
      }
    }

    this.helpTooltipElement.innerHTML = helpMsg;
    this.helpTooltip.setPosition(evt.coordinate);
    this.helpTooltipElement.classList.remove('hidden');
  }


  formatLength(line: any): any {
    const length = getLength(line);
    let output;
    if (length > 1000) {
      output = Math.round((length / 1000) * 100) / 100 + ' ' + 'km';
    } else {
      output = Math.round(length * 100) / 100 + ' ' + 'm';
    }
    return output;
  };

  formatArea(polygon: Geometry): any {
    const area = getArea(polygon);
    let output;
    if (area > 10000) {
      output = Math.round((area / 1000000) * 100) / 100 + ' ' + 'km<sup>2</sup>';
    } else {
      output = Math.round(area * 100) / 100 + ' ' + 'm<sup>2</sup>';
    }
    return output;
  };


  addMeasureInteraction(measuringType: MeasuringType): void {
    if (this.draw) {
      this.map?.removeInteraction(this.draw);
    }

    this.draw = new Draw({

      type: measuringType === MeasuringType.AREA ? GeometryType.POLYGON : GeometryType.LINE_STRING,
      source: this.getMeasuringLayer().layers[0].getSource() as VectorSource<Geometry>,
      style: measuringStyle
    });

    this.map!.addInteraction(this.draw);

    this.createMeasureTooltip();
    this.createHelpTooltip();

    this.draw.on('drawstart', (evt) => {
      this.sketch = evt.feature;

      let tooltipCoord = this.sketch.coordinate;

      this.listener = this.sketch.getGeometry().on('change', (event: any) => {
        const clone = this.sketch.clone();
        clone.getGeometry().transform(EPSG_3301, EPSG_3857)

        const geom = event.target;
        let output;
        if (geom instanceof Polygon) {
          output = this.formatArea(clone.getGeometry());
          tooltipCoord = geom.getInteriorPoint().getCoordinates();
        } else if (geom instanceof LineString) {
          output = this.formatLength(clone.getGeometry());
          tooltipCoord = geom.getLastCoordinate();
        }
        this.measureTooltipElement.innerHTML = output;
        this.measureTooltip.setPosition(tooltipCoord);
      });
    });

    this.draw.on(DrawEventType.DRAWEND, () => {
      this.measureTooltipElement.className = 'ol-tooltip ol-tooltip-static';
      this.measureTooltip.setOffset([0, -7]);
      this.sketch = null;
      this.measureTooltipElement = null;
      this.createMeasureTooltip();
      unByKey(this.listener);
    });

  }

  deleteMeasureTooltip(): void {
    this.map?.removeOverlay(this.measureTooltip);
    const matches = document.getElementsByClassName('ol-tooltip ol-tooltip-static');
    for (let i = 0; i < matches.length; i++) {
      matches[i].remove();
    }
  }

  switchPositioningMode(): void {
    switch (this.positioningMode) {
      case DEVICE_POSITIONING_MODE.NONE:
        this.setPositionMode(DEVICE_POSITIONING_MODE.POSITIONING);
        this.enableGeolocation();
        this.showDevicePosition(this.geolocation.getPosition());
        break;
      case DEVICE_POSITIONING_MODE.POSITIONING:
        this.setPositionMode(DEVICE_POSITIONING_MODE.NONE);
        this.disableGeolocation();
        this.hideDevicePosition();
        break;
    }
  }

  setPositionMode(mode: DEVICE_POSITIONING_MODE): void {
    this.positioningMode = mode;
  }

  isPositioningMode(): boolean {
    return this.positioningMode === DEVICE_POSITIONING_MODE.POSITIONING;
  }

  enableGeolocation(): void {
    this.geolocation.setTracking(true);
  }

  disableGeolocation(): void {
    this.geolocation.setTracking(false);
  }

  trackingMode(): void {
    this.showDevicePosition(this.geolocation.getPosition());
  }

  showDevicePosition(coordinate: any): void {
    if (coordinate) {
      this.devicePosition.setPosition([coordinate[0], coordinate[1]]);
    }
  }

  hideDevicePosition(): void {
    this.devicePosition.setPosition(undefined);
  }

  onSubmit(): void {
    if (this.form.controls.x?.value && this.form.controls.y?.value) {
      const coordinate: Coordinate = [this.form.controls.x?.value, this.form.controls.y?.value];
      this.setMarker(coordinate, () => {
      });
    }
  }


  setMarker(coordinate: Coordinate, callback: () => void): void {
    this.view.animate({
      center: coordinate,
      duration: 2000,
      zoom: 10
    });

    this.infoMarkerOverlay.setPosition(coordinate);
    this.infoMarkerCallback = callback;
  }

  hideMarker(): void {
    this.infoMarkerOverlay.setPosition(undefined);
    if (this.infoMarkerCallback) {
      this.infoMarkerCallback();
    }
  }

  onAdsItemSelected($event: any): void {
    if ($event) {
      this.form.controls.x.setValue($event.viitepunkt_x);
      this.form.controls.y.setValue($event.viitepunkt_y);
    }
  }

  changeMapMode(): void {
    if (this.mapMode === MapMode.NORMAL) {
      this.globalStateService.setMapMode(MapMode.HISTORICAL);
    } else {
      this.globalStateService.setMapMode(MapMode.NORMAL);
    }
  }

  switchMapMode(mode: MapMode): void {
    switch (mode) {
      case MapMode.NORMAL:
        this.setMapToNormalMode();
        break;
      case MapMode.HISTORICAL:
        this.setMapToHistoricalMode();
        break;
      default:
        this.setMapToNormalMode();
    }
  }

  setMapToHistoricalMode(): void {
    this.showLandParcelsLayer(false);
    this.showHistoricalLayers(true);
    this.registerHistoricalLayerInteractions();
    this.swipeContainer.style.display = 'block';
    this.openHistoryModal();
  }

  setMapToNormalMode(): void {
    this.showLandParcelsLayer(true);
    this.showHistoricalLayers(false);
    this.swipeContainer.style.display = 'none';
  }

  showLandParcelsLayer(show: boolean) {
    const l = this.getMapLayer(LAND_PARCELS_CURRENT);
    if (l) {
      l.setVisible(show);
    }
  }

  showHistoricalLayers(show: boolean) {
    historicalLayers.forEach(layer => {
      const l = this.getMapLayer(layer);
      if (l) {
        l.setVisible(show);
      }
    })
  }

  onSwipePositionChange(): void {
    const swipe = (document.getElementById('swipe') as HTMLInputElement);
    const value = +swipe.value;
    const divider = document.getElementById('divider');
    const newValue = swipe.offsetWidth * (value / this.swipeMax);
    divider.style.left = `${newValue}px`;

    if (this.map) {
      this.map.render();
    }
  }

  getHistoricalLayer(id: string): TileLayer {
    return this.getMapLayer(id).layers[0] as TileLayer;
  }

  registerHistoricalLayerInteractions(): void {
    historicalLayersLeft.forEach(id => this.registerPrerenderInteraction(id, true));
    historicalLayersRight.forEach(id => this.registerPrerenderInteraction(id));
  }

  registerPrerenderInteraction(interactionLayerId: string, inverted?: boolean): void {
    const leftLayer = this.getHistoricalLayer(interactionLayerId);
    if (!leftLayer) return;
    leftLayer.getListeners('prerender')?.forEach(l => leftLayer.removeEventListener('prerender', l));
    leftLayer.on('prerender', (event) => {
      const swipe = (document.getElementById('swipe') as HTMLInputElement);
      const value = +swipe.value;
      const ctx = event.context;
      const mapSize = this.map.getSize();
      const width = mapSize[0] * (value / this.swipeMax);

      let tl;
      let tr;
      let bl;
      let br;

      if (inverted) {
        tl = getRenderPixel(event, [0, 0]);
        tr = getRenderPixel(event, [width, 0]);
        bl = getRenderPixel(event, [0, mapSize[1]]);
        br = getRenderPixel(event, [width, mapSize[1]]);
      } else {
        tl = getRenderPixel(event, [width, 0]);
        tr = getRenderPixel(event, [mapSize[0], 0]);
        bl = getRenderPixel(event, [width, mapSize[1]]);
        br = getRenderPixel(event, mapSize);
      }

      ctx.save();
      ctx.beginPath();
      ctx.moveTo(tl[0], tl[1]);
      ctx.lineTo(bl[0], bl[1]);
      ctx.lineTo(br[0], br[1]);
      ctx.lineTo(tr[0], tr[1]);
      ctx.closePath();
      ctx.clip();
    });

    this.getHistoricalLayer(interactionLayerId).on('postrender', (event) => {
      const ctx = event.context;
      ctx.restore();
    });
  }

  convertToDate(value: NgbDate): Date {
    return new Date(value.year, value.month - 1, value.day);
  }

  swipe(): void {
    //
  }

  ngOnDestroy(): void {
    if (this.mapModeSubscription) {
      this.mapModeSubscription.unsubscribe();
    }
  }

  applyHistoryToLayer(layerName: string, date: NgbDateStruct): void {
    const historyString = `(valid_from BEFORE ${this.format(date)}T06:00:00.000000 OR valid_from IS NULL)  AND (valid_to AFTER ${this.format(date)}T06:00:00.000000 OR valid_to IS NULL)`;
    const source = this.getMapLayer(layerName).layers[0].getSource() as TileWMS;
    source.updateParams({CQL_FILTER: historyString});
    this.eventManager.broadcast(MAP_HISTORY_CHANGE_EVENT);
  }

  format(date: NgbDateStruct): string {
    if (!date) {
      return '';
    }
    const mdt = moment([date.year, date.month - 1, date.day]);
    if (!mdt.isValid()) {
      return '';
    }
    return mdt.format(this.DT_FORMAT);
  }

  getClickedSide(event: any): MapClickedSide {
    const swipe = (document.getElementById('swipe') as HTMLInputElement);
    const value = +swipe.value;
    const mapSize = this.map.getSize();
    const width = mapSize[0] * (value / this.swipeMax);
    if (event.screenX > width) {
      return MapClickedSide.RIGHT
    }
    return MapClickedSide.LEFT
  }

  openHistoryModal(): void {
    this.onSwipePositionChange();
    const modalRef = this.modalService.open(HistoryModalComponent);
    modalRef.result.then(
      result => {
        this.leftDate = result.leftDate;
        this.rightDate = result.rightDate;
        historicalLayersLeft.forEach(l => this.applyHistoryToLayer(l, result.leftDate));
        historicalLayersRight.forEach(l => this.applyHistoryToLayer(l, result.rightDate));
      }
    )
  }

  registerToCuSummaryPrint(): void {
    this.eventManager.subscribe(CUSUMMARY_PRINT, context => {
      this.print(context);
    })
  }

  showPrintPopover(): void {
    this.print(undefined);
  }

  closePrintPopup(): void {
    this.popoverPrint.close();
  }

  print(context): void {
    this.popoverPrint.open({context: context});
  }

  getLegendUrl(): string {
    return legendUrl;
  }
}
