import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { CdkDragEnd, CdkDragMove } from '@angular/cdk/drag-drop';
import { Subscription } from 'rxjs';
import { JhiEventManager } from 'ng-jhipster';
import { Point } from '@angular/cdk/drag-drop/drag-ref';
import { ViewportRuler } from '@angular/cdk/overlay';

export enum SwipeDirection {
  VERTICAL = 'VERTICAL',
  HORIZONTAL = 'HORIZONTAL',
}

export class SwipeListState {
  constructor(public height?: number, public dragEnd?: boolean, public transition?: string) {}
}

@Component({
  selector: 'app-swipe-list',
  templateUrl: './swipe-list.component.html',
  styleUrls: ['./swipe-list.component.scss'],
})
export class SwipeListComponent implements AfterViewInit, OnInit, OnDestroy {
  public static EVENT_SWIPE_LIST_STATE_CHANGE = 'EVENT_SWIPE_LIST_STATE_CHANGE';
  public static EVENT_SWIPE_LIST_SET_SIZE = 'EVENT_SWIPE_LIST_SET_SIZE';

  HORIZONTAL_TRANSITION = '0.5s ease-in-out';
  VERTICAL_TRANSITION = '0.5s ease-in-out';
  DIRECTION_FACTOR = 2;
  view = { width: 0, height: 0 };

  state: SwipeListState = new SwipeListState();

  items: any[] = [];
  selectedIndex = 0;
  lastIndex = 0;
  dragPosition: Point = { x: 0, y: 0 };
  listDefaultWidth = 0;
  dragStartHeight = 0;
  containerDefaultHeight = 0;
  containerNewHeight = 0;

  resizePreviousValue = 0;
  resizeEventSubscription!: Subscription;
  viewportResizeEventSubscription!: Subscription;

  minHeightPx = 0;
  maxHeightPx = 0;
  bottomMarginHeight = 0;

  dragEventActive = false;

  @Input() open = false;
  @Input() disableDetails = false;
  @Input() disableListClick = false;
  @Input() minHeight = 1;
  @Input() maxHeight = 70;
  @Input() snapPoints = [50];
  @Input() itemListTemplateRef!: TemplateRef<any>;
  @Input() itemDetailsTemplateRef!: TemplateRef<any>;
  @Output() onSelect: EventEmitter<any> = new EventEmitter();

  @ViewChild('swipeListComponentContainer', { static: false }) containerRef!: ElementRef;
  @ViewChild('swipeList', { static: false }) swipeListRef!: ElementRef;

  @Input()
  set data(value: any | any[]) {
    this.items = this.valueToArray(value);
    if (this.items.length) {
      this.lastIndex = this.items.length - 1;
      this.setSelectedItem(this.items[0]);

      if (this.containerRef) {
        this.state.dragEnd = true;
        this.broadcastStateChangeEvent();
      }
    }
  }

  constructor(private eventManager: JhiEventManager, private viewportRuler: ViewportRuler) {}

  ngOnInit(): void {
    if (this.itemDetailsTemplateRef) {
      this.subscribeToResizeEvent();
    }
    this.viewportResizeEventSubscription = this.viewportRuler.change().subscribe(() => {
      this.initViewport();
      this.setListDefaultWidth();
      this.horizontalCenterListElement();
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.initViewport();
      this.saveInitialSizes();
      this.calculateMinHeightPercentage();
      this.calculateHeightLimits();

      this.setBottomMarginHeight();
      this.setState();
      this.setContainerHeight(this.containerDefaultHeight, this.open);
    }, 10);

    if (this.open) {
      setTimeout(() => {
        this.toggleContainerHeight();
      }, 10);
    }
  }

  subscribeToResizeEvent(): void {
    this.resizeEventSubscription = this.eventManager.subscribe(SwipeListComponent.EVENT_SWIPE_LIST_SET_SIZE, (response: any) => {
      if (undefined !== response.content && null !== response.content && response.content >= 0) {
        this.resizePreviousValue = this.containerRef.nativeElement.offsetHeight;
        this.setContainerHeight(response.content, true, true);
      } else if (this.resizePreviousValue) {
        this.setContainerHeight(this.resizePreviousValue);
      }
    });
  }

  initViewport(): void {
    this.view = {
      width: document.body.clientWidth,
      height: window.innerHeight,
    };
  }

  calculateHeightLimits(): void {
    this.minHeightPx = Math.max(Math.floor(this.view.height * (this.minHeight / 100)), this.containerDefaultHeight);
    this.maxHeightPx = Math.floor(this.view.height * (this.maxHeight / 100));
  }

  calculateMinHeightPercentage(): void {
    this.minHeight = Math.max(this.minHeight, Math.floor((this.swipeListRef.nativeElement.offsetHeight / this.view.height) * 100));
  }

  saveInitialSizes(): void {
    this.setContainerDefaultHeight();
    this.setListDefaultWidth();
    this.setContainerNewHeight();
  }

  setBottomMarginHeight(): void {
    this.bottomMarginHeight = this.getOutermostContainerHeight() - this.containerRef.nativeElement.offsetHeight;
  }

  setState(): void {
    this.state = new SwipeListState(this.getContainerViewportHeight(this.containerDefaultHeight), false);
  }

  setContainerDefaultHeight(): void {
    this.containerDefaultHeight = this.containerRef.nativeElement.offsetHeight + 1;
  }

  setListDefaultWidth(): void {
    this.listDefaultWidth = this.swipeListRef.nativeElement.offsetWidth + 1;
  }

  setContainerNewHeight(): void {
    this.containerNewHeight = this.containerDefaultHeight;
  }

  validateMinHeight(height: number, enabled: boolean): number {
    return enabled && height < this.containerDefaultHeight ? this.containerDefaultHeight : height;
  }

  validateMaxHeight(height: number, enabled: boolean): number {
    return enabled && height > this.maxHeightPx ? this.maxHeightPx : height;
  }

  broadcastStateChangeEvent(): void {
    this.eventManager.broadcast({
      name: SwipeListComponent.EVENT_SWIPE_LIST_STATE_CHANGE,
      content: this.state,
    });
  }

  setContainerHeight(height: number, disableEmit = false, overrideDefaultHeight = false): void {
    height = this.validateMinHeight(height, !overrideDefaultHeight);
    height = this.validateMaxHeight(height, !overrideDefaultHeight);

    this.state.dragEnd = !(this.dragStartHeight > 0);
    this.state.transition = this.state.dragEnd ? this.VERTICAL_TRANSITION : '';

    if (this.dragStartHeight) {
      this.containerRef.nativeElement.style.height = height + 'px';
    } else {
      if (height !== this.containerRef.nativeElement.offsetHeight) {
        this.containerRef.nativeElement.style.transition = this.VERTICAL_TRANSITION;
        this.containerRef.nativeElement.style.height = height + 'px';
        this.state.transition = this.VERTICAL_TRANSITION;
      }
    }

    if (!disableEmit) {
      this.state.height = this.getContainerViewportHeight(height);
      this.broadcastStateChangeEvent();
    }

    if (this.containerNewHeight <= height) {
      this.containerNewHeight = height;
    } else {
      setTimeout(() => {
        this.containerNewHeight = height;
      }, 400);
    }
  }

  getOutermostContainerHeight(): number {
    const offsetParent = this.containerRef.nativeElement.offsetParent;
    let parentElem = offsetParent && offsetParent.className.indexOf('cdk-global') < 0 ? offsetParent : undefined;

    if (parentElem) {
      while (parentElem.offsetParent && parentElem.offsetParent.className.indexOf('cdk-global') < 0) {
        parentElem = parentElem.offsetParent;
      }
    }
    return parentElem && parentElem.clientHeight >= 0 ? parentElem.clientHeight : this.containerRef.nativeElement.offsetHeight;
  }

  toggleContainerHeight(): void {
    if (!this.dragEventActive && !this.disableDetails) {
      const newHeight =
        this.containerRef.nativeElement.offsetHeight !== this.containerDefaultHeight
          ? this.containerDefaultHeight
          : Math.max(Math.floor(this.maxHeightPx * 0.6), this.minHeightPx + 1);
      this.setContainerHeight(newHeight);
    }
  }

  changeActiveIndex(increaseIndex: boolean): void {
    this.selectedIndex = increaseIndex ? this.getNextIndex() : this.getPrevIndex();
    this.setSelectedItem(this.items[this.selectedIndex]);
  }

  getNextIndex(): number {
    return Math.min(this.selectedIndex + 1, this.lastIndex);
  }

  getPrevIndex(): number {
    return Math.max(0, this.selectedIndex - 1);
  }

  public setSelectedItem = (item: object) => {
    const newIndex = this.items.indexOf(item);
    if (newIndex >= 0) {
      this.selectedIndex = newIndex;
      if (this.swipeListRef) {
        this.playListTransitionAnimation();
      }
      this.onSelect.emit(item);
    }
  };

  playListTransitionAnimation(): void {
    const element = this.swipeListRef.nativeElement.firstElementChild;
    if (element) {
      element.style.transition = this.HORIZONTAL_TRANSITION;
      this.horizontalCenterListElement();
    }
  }

  horizontalCenterListElement(): void {
    this.dragPosition = { x: -(this.selectedIndex * this.listDefaultWidth), y: this.dragPosition.y };
  }

  containerDragMoveEvent(event: CdkDragMove): void {
    this.dragEventActive = true;
    if (!this.itemDetailsTemplateRef || this.disableDetails) {
      event.source._dragRef.reset();
      return;
    }

    const elem = this.containerRef.nativeElement;
    elem.style.transition = null;

    if (!this.dragStartHeight) {
      this.dragStartHeight = elem.offsetHeight;
    }

    this.setContainerHeight(this.dragStartHeight + -event.distance.y);

    if (elem.offsetHeight <= this.minHeightPx) {
      if (event.distance.y > 0) {
        this.setContainerHeight(this.containerDefaultHeight);
      }
    } else if (elem.offsetHeight >= this.maxHeightPx) {
      this.setContainerHeight(this.maxHeightPx);
    }

    event.source._dragRef.reset();
  }

  containerDragEndEvent(event: CdkDragEnd): void {
    const elem = this.containerRef.nativeElement;

    this.dragStartHeight = 0;

    if ((this.isDetailsVisible() && this.snapPoints.length) || elem.offsetHeight > this.containerDefaultHeight) {
      const sizeInPercent = (elem.offsetHeight / this.maxHeightPx) * 100;

      let snapped = false;

      this.snapPoints.forEach((percent, idx) => {
        const condition =
          event.distance.y < 0
            ? sizeInPercent < percent && (!this.snapPoints[idx - 1] || sizeInPercent > this.snapPoints[idx - 1])
            : sizeInPercent > percent && (!this.snapPoints[idx + 1] || sizeInPercent < this.snapPoints[idx + 1]);
        if (condition && !snapped) {
          this.setContainerHeight(percent <= this.minHeight ? this.containerDefaultHeight : this.maxHeightPx * (percent / 100));
          snapped = true;
          return;
        }
      });

      if (!snapped) {
        this.setContainerHeight(elem.offsetHeight);
      }
    }
    this.setDragEnded();
  }

  listDragMoveEvent(event: CdkDragMove): void {
    this.dragEventActive = true;
    const direction = this.getSwipeDirection(event.distance.x, event.distance.y);

    if (event.distance.x >= 0 && this.selectedIndex === 0 && direction === SwipeDirection.HORIZONTAL) {
      // Prevent right drag if first element of list is active
      this.dragPosition = { x: 0, y: 0 };
    } else if (event.distance.x <= 0 && this.selectedIndex === this.lastIndex && direction === SwipeDirection.HORIZONTAL) {
      // Prevent left drag if last element of list is active
      this.dragPosition = { x: -(this.selectedIndex * this.listDefaultWidth), y: this.dragPosition.y };
    } else {
      if (direction === SwipeDirection.VERTICAL && !this.disableDetails) {
        this.containerDragMoveEvent(event);
      } else if (this.items.length > 1) {
        // Default behaviour
        const calcX = -(this.selectedIndex * this.listDefaultWidth) + event.distance.x;
        let limitX;
        if (event.distance.x < 0) {
          limitX = Math.max(-(this.getNextIndex() * this.listDefaultWidth), calcX);
        } else {
          limitX = Math.min(-(this.getPrevIndex() * this.listDefaultWidth), calcX);
        }

        this.dragPosition = {
          x: limitX,
          y: this.dragPosition.y,
        };
      } else {
        event.source._dragRef.reset();
        return;
      }
    }
  }

  listDragEndEvent(event: CdkDragEnd): void {
    const direction = this.getSwipeDirection(event.distance.x, event.distance.y);
    if (direction === SwipeDirection.VERTICAL) {
      this.containerDragEndEvent(event);
    } else if (direction === SwipeDirection.HORIZONTAL) {
      this.dragStartHeight = 0;
      this.setContainerHeight(this.containerRef.nativeElement.offsetHeight, true);
      this.changeActiveIndex(event.distance.x < 0);
    }

    this.setDragEnded();
  }

  setDragEnded(): void {
    setTimeout(() => {
      this.dragEventActive = false;
    }, 500);
  }

  isDetailsVisible(): boolean {
    return !this.disableDetails && this.containerRef && this.containerNewHeight > this.minHeightPx;
  }

  getSwipeDirection(x: number, y: number): SwipeDirection {
    return Math.abs(y / this.DIRECTION_FACTOR) > Math.abs(x) ? SwipeDirection.VERTICAL : SwipeDirection.HORIZONTAL;
  }

  getContainerViewportHeight(height: number): number {
    return height + this.bottomMarginHeight;
  }

  hasActiveSelectedItem(): boolean {
    return undefined !== this.selectedIndex && null !== this.selectedIndex;
  }

  valueToArray(data: any | any[]): any[] {
    if (data && Array.isArray(data)) {
      return data;
    }
    return data && !Array.isArray(data) ? [data] : [];
  }

  ngOnDestroy(): void {
    if (this.itemDetailsTemplateRef && this.resizeEventSubscription) {
      this.resizeEventSubscription.unsubscribe();
    }
    if (this.viewportResizeEventSubscription) {
      this.viewportResizeEventSubscription.unsubscribe();
    }
  }
}
