<div class="swipe-list" #swipeListComponentContainer>
    <div *ngIf="(hasActiveSelectedItem() || items.length) && itemDetailsTemplateRef && !disableDetails"
         class="d-flex flex-row show-swipe-list-item-details"
         cdkDrag
         cdkDragLockAxis="y"
         [cdkDragDisabled]="!hasActiveSelectedItem() || disableDetails"
         (cdkDragEnded)="containerDragEndEvent($event)"
         (cdkDragMoved)="containerDragMoveEvent($event)">
        <button [disabled]="!hasActiveSelectedItem()"
                (click)="toggleContainerHeight()"
                class="swipe-icon-button d-flex justify-content-center"
                [ngClass]="{'swipe-icon-small-btn no-shadow': !isDetailsVisible()}"
                aria-label="Drag button">
           <span class="drag-icon"></span>
        </button>
    </div>
    <div class="swipe-list-item-scroll">
        <div *ngIf="hasActiveSelectedItem() && !disableDetails" [style.display]="isDetailsVisible() ? 'flex' : 'none'" class="swipe-list-item-details-scrollable">
            <div *ngTemplateOutlet="itemDetailsTemplateRef, context: { item: items[selectedIndex], selectFn: setSelectedItem, list: items }"></div>
        </div>
        <div class="row" [style.display]="!isDetailsVisible() ? 'flex' : 'none'">
            <div class="col-2 swipe-list-button swipe-list-left" (click)="changeActiveIndex(false)" *ngIf="items.length > 1" aria-label="Previous list element button">
                <button [disabled]="selectedIndex===0" class="swipe-icon-button">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"/></svg>
                </button>
            </div>
            <div class="overflow-hidden" [ngClass]="{'col-8': items.length > 1, 'col-12': items.length <= 1}" #swipeList (click)="!disableListClick ? toggleContainerHeight() : $event">
                <ul *ngIf="items.length"
                          class="list-group list-group-horizontal swipe-list-tabs flex-fill col-12"
                          cdkDrag
                          [cdkDragFreeDragPosition]="dragPosition"
                          (cdkDragEnded)="listDragEndEvent($event)"
                          (cdkDragMoved)="listDragMoveEvent($event)">
                    <li *ngFor="let item of items; let idx = index" class="col-12 list-group-item border-0">
                        <div class="d-flex flex-row justify-content-between w-100" [ngClass]="{'tab-not-activated':!hasActiveSelectedItem()}">
                            <div class="d-flex flex-column flex-fill">
                                <div *ngTemplateOutlet="itemListTemplateRef, context: { item: item }"></div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="col-2 swipe-list-button swipe-list-right" (click)="changeActiveIndex(true)" *ngIf="items.length > 1">
                <button [disabled]="selectedIndex===items.length-1" class="swipe-icon-button" aria-label="Next list element button">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"/></svg>
                </button>
            </div>
        </div>
    </div>
</div>
