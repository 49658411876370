<div class="search-box">
    <inads-search (selectedItem)="loadGeometry($event)"></inads-search>
</div>

<app-cadastre-unit [historyDate]="originalDate"></app-cadastre-unit>
<app-kolvik [historyDate]="originalDate"></app-kolvik>

<div>
  <map-wrapper></map-wrapper>
</div>
