import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {InfoRequestType, MapMode} from '../map/map.component';
import {CadastreUnit} from '../../api/land-parcel-api/model/cadastre-unit.model';


export enum AreaUnit {
  M2 = 'M2',
  HA = 'HA'
}

export enum MapClickedSide {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT'
}

export interface StateOfHistoricalMode {
  side?: MapClickedSide;
  layer?: string;
  action?: InfoRequestType;
  leftDate?: string;
  rightDate?: string;
}

@Injectable({providedIn: 'root'})
export class GlobalStateService {
  private sidebarStatus = new BehaviorSubject<boolean>(false);
  private currentAreaUnit = new BehaviorSubject<AreaUnit>(AreaUnit.M2);
  private mapMode = new BehaviorSubject<MapMode>(MapMode.NORMAL);
  private historicalState = new BehaviorSubject<StateOfHistoricalMode>({});
  private lastCadastreUnit = new BehaviorSubject<CadastreUnit>(null);
  private lastCadastreUnitSummary = new BehaviorSubject<CadastreUnit>(null);
  private lastCadastreUnitCode = new BehaviorSubject<string>(null);
  private printPreview = new BehaviorSubject<boolean>(false);
  private printPreviewDim = new BehaviorSubject<number[]>([]);
  private backdrop = new BehaviorSubject<boolean>(false);

  constructor() {
  }

  getSidebarStatus(): Observable<boolean> {
    return this.sidebarStatus.asObservable();
  }

  close(): void {
    this.sidebarStatus.next(false);
  }

  open(): void {
    this.sidebarStatus.next(true);
  }

  getAreaUnit(): Observable<AreaUnit> {
    return this.currentAreaUnit.asObservable();
  }

  setAreaUnit(newValue): void {
    this.currentAreaUnit.next(newValue);
  }

  getMapMode(): Observable<MapMode> {
    return this.mapMode.asObservable();
  }

  setMapMode(value: MapMode): void {
    this.mapMode.next(value);
  }

  setHistoricalState(state: StateOfHistoricalMode): void {
    this.historicalState.next(state);
  }

  getHistoricalState(): Observable<StateOfHistoricalMode> {
    return this.historicalState.asObservable();
  }

  getHistoricalStateValue(): StateOfHistoricalMode {
    return this.historicalState.getValue();
  }

  setLastCadastreUnit(cadastreUnit: CadastreUnit): void {
    this.lastCadastreUnit.next(cadastreUnit);
  }

  getLastCadastreUnit(): CadastreUnit {
    return this.lastCadastreUnit.getValue();
  }

  setLastCadastreUnitCode(code: string | null): void {
    this.lastCadastreUnitCode.next(code);
  }

  getLastCadastreUnitCode(): string | null {
    return this.lastCadastreUnitCode.getValue();
  }

  setLastCadastreUnitSummary(cadastreUnit: CadastreUnit): void {
    this.lastCadastreUnitSummary.next(cadastreUnit);
  }

  getLastCadastreUnitSummary(): CadastreUnit {
    return this.lastCadastreUnitSummary.getValue();
  }

  showPrintPreview(): void {
    this.printPreview.next(true);
  }

  hidePrintPreview(): void {
    this.printPreview.next(false);
  }

  getPrintPreview(): Observable<boolean> {
    return this.printPreview.asObservable();
  }

  updatePrintPreviewDim(value: number[]): void {
    this.printPreviewDim.next(value);
  }

  getPrintPreviewDim(): Observable<number[]> {
    return this.printPreviewDim.asObservable();
  }

  showBackdrop(): void {
    this.backdrop.next(true);
  }

  hideBackdrop(): void {
    this.backdrop.next(false);
  }

  getBackdrop(): Observable<boolean> {
    return this.backdrop.asObservable();
  }

}
