import {Pipe, PipeTransform} from '@angular/core';
import {AreaUnit} from "../service/global-state.service";


@Pipe({name: 'areaUnit'})
export class AreaUnitPipe implements PipeTransform {
  transform(value: number, unit: AreaUnit): number {
    if (unit === AreaUnit.HA) {
      return value / 10000;
    }
    return value;
  }
}
