<div class="backdrop" *ngIf="showBackdrop"></div>
<div class="map-wrapper" [ngClass]="{'blur':showBackdrop}">
  <app-map #map></app-map>
  <div #preview>
    <div class="preview-top" #top></div>
    <div class="preview-left" #left></div>
    <div class="preview-right" #right></div>
    <div class="preview-bottom" #bottom></div>
  </div>
</div>
