import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ExportFormat} from '../../api/land-parcel-api/model/export-format.model';
import {LandParcelService} from '../../api/land-parcel-api/service/land-parcel.service';
import {InfoRequestType} from '../map/map.component';
import {CadastreUnitService} from '../../api/land-parcel-api/service/cadastre-unit.service';


@Component({
  selector: 'download-modal',
  templateUrl: './download-modal.component.html'
})
export class DownloadModalComponent {
  selectedFormat = null;
  date: string;
  code: string;
  type: InfoRequestType = null;
  kolvikId: number;

  constructor(public activeModal: NgbActiveModal,
              private landParcelService: LandParcelService,
              private cadastreUnitService: CadastreUnitService) {

  }

  get formats(): string[] {
    return [ExportFormat.GML2, ExportFormat.GML31, ExportFormat.GML32, ExportFormat.CSV, ExportFormat.KML,
      ExportFormat.SHAPEFILE, ExportFormat.GEOJSON];
  }

  download(): void {
    if (this.type === InfoRequestType.LAND_PARCEL) {
      this.downloadLandParcel();
    }

    if (this.type === InfoRequestType.CADASTRE_UNIT) {
      this.downloadCadastreUnit();
    }
  }

  downloadLandParcel(): void {
    if (this.selectedFormat && this.kolvikId) {
      this.landParcelService.exportLandParcel(this.kolvikId, this.selectedFormat).subscribe((response: any) => {
          this.saveFile(response, 'export.zip');
        }, error => {
        }
      );
    }
  }

  downloadCadastreUnit(): void {
    if (this.selectedFormat && this.code) {
      this.cadastreUnitService.exportCadastreUnits(this.date, this.selectedFormat, [this.code], null, null ).subscribe((response: any) => {
          this.saveFile(response, 'export.zip');
        }, error => {
        }
      );
    }
  }

  private saveFile(blob: any, filename: string): void {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 0);
    }
  }


}
