import {Component, OnDestroy, OnInit} from '@angular/core';
import {LoaderService} from '../../shared/service/loader.service';
import {GlobalStateService} from "../../shared/service/global-state.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy {
  // Sidebar config
  mode = 'over';
  dock = true;
  showBackdrop = false;
  closeOnClickBackdrop = true;
  closeOnClickOutside = true;

  sidebarOpened = false;
  showLoader = false;

  sidebarStatusSubscription: Subscription;
  loaderSubscription: Subscription;

  constructor(private loaderService: LoaderService,
              private sidebarService: GlobalStateService) {
  }

  ngOnInit(): void {
    this.subscribeToLoader();
    this.subscribeToSidebarStatus();
  }

  subscribeToLoader(): void {
    this.loaderSubscription = this.loaderService.getLoader().subscribe(value => (this.showLoader = value));
  }

  subscribeToSidebarStatus(): void {
    this.sidebarStatusSubscription = this.sidebarService.getSidebarStatus().subscribe(value => (this.sidebarOpened = value));
  }

  toggleSidebar(): void {
    this.sidebarService.open();
  }

  ngOnDestroy(): void {
    if (this.loaderSubscription) {
      this.loaderSubscription.unsubscribe();
    }
    if (this.sidebarStatusSubscription) {
      this.sidebarStatusSubscription.unsubscribe();
    }
  }
}
